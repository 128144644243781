/*
 * Bibliography page
 */

import React from 'react';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Finder from '../partials/artworks/finder.jsx';
import Listing from '../partials/listings/bibliography/index.jsx';
import util from '../utilities';


/**
 * Class representing the editorial pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 */
class Bibliography extends React.Component {
    render() {

        const total = util.try(() => this.props.bibliographies.total) || 0;

        let hasFilters = false;
        util.try(() => {
            Object.keys(this.props.filters).map((key) => {
                if (util.isArray(this.props.filters[key].value)) {
                    hasFilters = true;
                }
            });
        });

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={'/bibliography'} />
                <section className='p4 md-px6 sm-py7'>
                    <div className='mt3 mb3 sm-mt0 sm-mb0'>
                        <h1 className='m0'>
                            Bibliography
                        </h1>
                        <div>
                            <span dangerouslySetInnerHTML={{
                                __html: `${total} ${(total === 1) ? 'Result' : 'Results'}.`
                            }} />
                                {hasFilters &&
                                    <a className='ml2 link-underline' href='/bibliography'>Reset</a>
                                }
                            </div>
                        </div>
                </section>
                <Finder baseUrl={'bibliography'} filters={this.props.filters} alignCol={true} />
                <Listing
                    filters={this.props.filters}
                    pages={util.try(() => this.props.bibliographies.pages) || 1}
                    totalPages={util.try(() => this.props.bibliographies.totalPages)}
                    items={util.try(() => this.props.bibliographies.posts) || []} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );
    }
}

module.exports = Bibliography;
