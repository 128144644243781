/*
 * Search listing container
 */

import React from 'react';
import ListingContainer from '../base/container.jsx';
import Pagination from '../shared/pagination.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing a Search listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} page
 * @prop {number} total
 * @prop {number} completeTotal
 * @prop {boolean} morePages
 * @prop {string} term
 * @prop {string} type
 */
class SearchListingContainer extends ListingContainer {

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
        this.state.total = this.props.total || 0;
        this.state.completeTotal = this.props.completeTotal || 0;
        this.state.totalPages = this.props.totalPages || 1;
        this.state.type = this.props.type; // Only updated after load
        this.state.filters = {
            type: {
                value: this.props.type,
                options: [
                    {
                        label: 'Artworks',
                        value: 'artwork'
                    },
                    {
                        label: 'All',
                        value: 'all'
                    },
                ]
            }
        };
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            total: data.total,
            completeTotal: data.completeTotal,
            totalPages: data.totalPages,
            type: JSON.parse(JSON.stringify(util.try(() => this.state.filters.type.value)))
        });
    }

    render() {

        const filters = util.try(() => {
            return this.state.filters.type.options.map((filter, i) => {
                i++;
                const isCurrent = filter.value === this.state.type;
                const count = (isCurrent) ? this.state.total : this.state.completeTotal - this.state.total;
                const border = (!isCurrent && i % 5 !== 0) ? 'border-right border-white' : '';
                const mask = (isCurrent && i > 1) ? 'mln1 border-left border-grey-001' : '';
                return (
                    <button
                        key={i}
                        onClick={this.handleFilter.bind(this, 'type', filter.value)}
                        className={`btn border-0 left-align relative block col col-6 sm-col-4 ${isCurrent && 'bg-white'} ${mask} p0 pt4`}>
                        <div className='relative'>
                            <div className={`absolute right-0 top-0 bottom-0 ${border}`} />
                            <div className='relative px4 md-px6 pb4'>
                                <div className='truncate'>
                                    <span
                                        className={isCurrent ? '' : 'link-underline'}
                                        dangerouslySetInnerHTML={{
                                            __html: `${filter.label} (${count})`
                                        }} />
                                </div>
                            </div>
                        </div>
                    </button>
                );
            });
        });

        return (
            <section className='relative'>

                {/* Header */}
                <section className='border-bottom border-grey-002 p4 md-px6 sm-py7'>
                    <div className='mt3 mb3 sm-mt0 sm-mb0'>
                        <h1 className='m0'>
                            {`Search ‘${this.props.term}’`}
                        </h1>
                        <div dangerouslySetInnerHTML={{
                            __html: `${this.state.completeTotal} ${this.state.completeTotal === 1 ? 'result' : 'results'}`
                        }} />
                        </div>
                </section>

                <div className='relative'>

                    {/* Filter */}
                    <div className='bg-grey-001 clearfix'>
                        {filters}
                    </div>

                    {/* Listing */}
                    <Listing
                        path='/api/search'
                        items={this.props.items}
                        query={{
                            term: this.props.term,
                            page: this.state.page,
                            type: util.try(() => this.state.filters.type.value)
                        }}
                        type={this.state.type}
                        onLoad={this.handleLoad}
                        onLoading={this.handleLoading} />

                    {/* Pagination */}
                    <Pagination
                        current={this.state.page}
                        total={this.state.totalPages}
                        navigate={this.handlePage} />

                </div>

            </section>
        );

    }
}

module.exports = SearchListingContainer;
