import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import ContentModules from '../../partials/editorial/modules.jsx';
import Footer from '../../partials/global/footer.jsx';
import moment from 'moment';
import util from '../../utilities';


class NewsDetail extends React.Component {


    render() {

        let datePublished = 'Published on ' + util.try(() => moment(this.props.post.date).format('Do MMMM YYYY'));
        if (util.try(() => this.props.post.news_category[0])) {
            datePublished = (
                <div>
                    {datePublished + ' in '}<a className='link-underline' href={'/news?category=' + this.props.post.news_category[0].slug}>{this.props.post.news_category[0].name}</a>
                </div>
            );
        }

        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />

                {/* Title */}
                <section className='border-bottom border-grey-002 py6 sm-p0 sm-pt6 sm-mt2'>
                    <div className='relative clearfix'>
                        <div className='relative col12 sm-col-6 px4 sm-py0 md-px6'>
                            <h1
                                className='m0 mb2 line-height-3'
                                dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                        </div>
                        <div className='relative col col-12 sm-col-6 px4 pb2 md-px6 sm-pb6'>
                            {util.try(() => this.props.post.news_category[0].name)}
                        </div>
                        <div className='relative col-right col-12 sm-col-6 px4 sm-py0 md-px6 sm-pb6 sm-border-left sm-border-right border-grey-002'>
                            <div className='md-mb2'>
                                {datePublished}
                            </div>
                        </div>
                    </div>
                </section>

                <ContentModules modules={util.try(() => this.props.post.acf.modules)} />

                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />

            </Page>
        );
    }

}

module.exports = NewsDetail;
