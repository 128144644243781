/*
 * Series listing
 */

import React from 'react';
import AjaxListing from '../base/infinite-ajax-listing.jsx';
import ListingItem from './item.jsx';
import Message from '../shared/message.jsx';
import Loading from '../shared/loading.jsx';
import util from '../../../utilities';


/**
 * Class representing a Series listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 * @prop {number} featured
 */
class SeriesListing extends AjaxListing {

    /**
     * Render
     */
    render() {

        const items = util.try(() => {
            return this.state.items.map((item, i) => {
                return (
                    <ListingItem
                        key={item.slug}
                        index={i}
                        item={item} />
                );
            });
        });

        return (
            <div>
                <div>

                    {/* Listing */}
                    {this.state.status === 200 && this.state.items.length > 0 &&
                        <span>
                            {items}
                            {this.state.nextPage &&
                                <Loading active={this.state.loading} />
                            }
                        </span>
                    }

                    {this.state.status === 200 && this.state.items.length === 0 &&
                        <Message message='Sorry, no series found.' />
                    }
                    {this.state.status !== 200 &&
                        <Message message='Sorry, something went wrong. Please try again later.' />
                    }

                </div>

                {/* Mask */}
                {this.state.loading &&
                    <div className='bg-darken-1 z2 absolute top-0 bottom-0 left-0 right-0' />
                }

            </div>
        );

    }

}

module.exports = SeriesListing;
