import React from 'react';
import Image from '../images/image.jsx';
import util from '../../utilities';

class InfoWindow extends React.Component {

    handleClose() {
        if (typeof this.props.onHandleClose === 'function') {
            this.props.onHandleClose();
        }
    }

    render() {

        const {
            id,
            title,
            link,
            address,
            image,
            type,
            dateRange
        } = this.props;

        return (
            <div
                id={id}
                className='bg-white'
                style={{
                    fontSize: '13.504px',
                    lineHeight: '1.6'
                }}>
                <div className='p5 rational'>
                    <i
                        onClick={this.handleClose.bind(this)}
                        className='ml3 icon-cross right cursor-pointer' />
                    <div className='overflow-hidden bold'>
                        <a href={link}>{title}</a>
                    </div>
                    <div>{address}</div>
                    <div>—</div>
                    <div className='capitalize'>
                        {type}
                    </div>
                    <div>{util.try(() => dateRange)}</div>
                    {util.try(() => image && (
                        <div className='aspect-16-9 overflow-hidden'>
                            <Image
                                noJs
                                className='pt5 absolute'
                                image={image} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

}

module.exports = InfoWindow;
