/*
 * Columns
 */

import React from 'react';
import util from '../../utilities';

/**
 * Columns
 * @param {array} items
 * @param {number} count
 * @param {jsx} firstItem
 */
module.exports = (props) => {

    if (!util.isArray(props.items)) {
        return (<span />);
    }

    const count = props.count || 2;

    let cols = [];
    let i = 0;
    while (i < count) {
        cols.push([]);
        i++;
    }

    let colIndex = 0;
    props.items.forEach((item, i) => {
        cols[colIndex].push(
            <span key={i}>
                {props.items[i]}
            </span>
        );
        colIndex = (colIndex >= (count - 1)) ? 0 : colIndex + 1;
    });

    if (props.firstItem) {
        cols[0].unshift(
            <span key='firstItem'>
                {props.firstItem}
            </span>
        );
    }

    cols = cols.map((col, i) => {
        return (
            <div
                key={i}
                className={`col col-${Math.floor(12 / count)}`}>
                {col}
            </div>
        );
    });

    return (
        <span>
            {cols}
        </span>
    );

};
