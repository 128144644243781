/*
 * Search listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Search listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class SearchItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        let type;
        let taxonomy;
        let subtitle;

        switch(util.try(() => this.props.item.type)) {
        case 'news':
            type = {
                label: 'News',
                url: '/news'
            };
            if (util.try(() => this.props.item.news_category[0])) {
                taxonomy = {
                    label: this.props.item.news_category[0].name,
                    url: `/news?category=${this.props.item.news_category[0].slug}`
                };
            }
            break;
        case 'exhibition':
            type = {
                label: 'Exhibitions',
                url: '/exhibitions'
            };
            if (util.try(() => this.props.item.exhibition_type[0])) {
                taxonomy = {
                    label: this.props.item.exhibition_type[0].name,
                    url: `/exhibitions?type=${this.props.item.exhibition_type[0].slug}`
                };
            }
            let dateRange;
            if (util.try(() => this.props.item.acf.start_date)) {
                dateRange = `${moment(this.props.item.acf.start_date, 'YYYYMMDD').format('DD MMMM YYYY')}`;
                if (util.try(() => this.props.item.acf.end_date)) {
                    dateRange += util.try(() => ` - ${moment(this.props.item.acf.end_date, 'YYYYMMDD').format('DD MMMM YYYY')}`);
                }
            }
            subtitle = (
                <span dangerouslySetInnerHTML={{__html: dateRange}} />
            );
            break;
        case 'video':
            type = {
                label: 'Videos',
                url: '/videos'
            };
            break;
        case 'series':
            type = {
                label: 'Series',
                url: '/series'
            };
            break;
        }

        const bgColor = (this.props.index % 2 === 0) ? 'bg-white' : 'bg-grey-000';

        return (
            <div className={`block clearfix ${bgColor}`}>

                <div className='col col-12 sm-col-8 md-col-9 p4 md-p6'>

                    {type &&
                        <div className='mb4'>
                            <a
                                className='link'
                                href={type.url}
                                dangerouslySetInnerHTML={{__html: type.label}} />
                            <i className='icon-arrow-right px2' />
                            {taxonomy &&
                                <a
                                    className='link'
                                    href={taxonomy.url}
                                    dangerouslySetInnerHTML={{__html: taxonomy.label}} />
                            }
                        </div>
                    }

                    <h3 className='m0'>
                        <a
                            className='link'
                            href={util.try(() => this.props.item.link)}
                            dangerouslySetInnerHTML={{
                                __html: util.try(() => this.props.item.title.rendered) ||
                                util.try(() => this.props.item.post_title)
                            }} />
                    </h3>

                    {subtitle}

                </div>

                <div className='col col-12 sm-col-4 md-col-3 p4 md-p6'>

                    <a href={util.try(() => this.props.item.link)}>
                        <h3 className='m0'>
                            {util.try(() => this.props.item.acf.listing_image) &&
                                <Image
                                    className='col-12'
                                    smWidth={50}
                                    mdWidth={40}
                                    lgWidth={30}
                                    aspect='16-9'
                                    image={this.props.item.acf.listing_image} />
                            }
                        </h3>
                    </a>

                </div>

            </div>
        );

    }

}

module.exports = SearchItem;
