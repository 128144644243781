/*
 * Exhibitions
 */

import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Listing from '../../partials/listings/collections/index.jsx';
import Filter from '../../partials/ui/dropdown-filter.jsx';
import util from '../../utilities';


/**
 * Class representing the exhibitions listing
 * @extends React.component
 * @prop {object} post
 * @prop {array} exhibitions
 */
class ExhibitionsListing extends React.Component {

    render() {

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />
                <Listing
                    page={util.try(() => this.props.collections.page) || 1}
                    totalPages={util.try(() => this.props.collections.totalPages)}
                    items={util.try(() => this.props.collections.posts) || []} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );

    }
};

module.exports = ExhibitionsListing;
