/*
 * Collections listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Collections listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class CollectionsItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        const bgColor = this.props.index % 2 === 0
            ? 'bg-white'
            : 'bg-grey-000';

        const item = this.props.item;

        return (
            <div className={`clearfix ${bgColor}`}>
                <div className='clearfix p4 md-p6'>
                    <div className='bold'>
                        {item.title.rendered}
                    </div>
                    <div className={`col col-12 sm-col-${item.acf.detail_page ? '6' : '12'}`}>
                        {item.acf.address || item.acf.location.address}
                    </div>
                    {item.acf.detail_page && (
                        <div className='col col-12 sm-col-6'>
                            <div className='pt2 sm-p0 sm-pl6'>
                                <a
                                    className='link-underline'
                                    href={item.link}>
                                    View Details
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );

    }

}

module.exports = CollectionsItem;
