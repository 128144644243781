/*
 * Home
 */

import React from 'react';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Artwork from '../partials/listings/artworks/item.jsx';
import Exhibition from '../partials/home/exhibition.jsx';
import News from '../partials/listings/news/item.jsx';
import Instagram from '../partials/home/instagram.jsx';
import Twitter from '../partials/home/twitter.jsx';
import Newsletter from '../partials/global/newsletter.jsx';
import util from '../utilities';


/**
 * Class representing the homepage
 * @extends React.component
 */
class Home extends React.Component {
    render() {

        const artworks = util.try(() => {
            return this.props.page.acf.featured_artworks.map((artwork, i) => {
                return (
                    <div
                        key={i}
                        className='relative col col-12 sm-col-6'>
                        <Artwork
                            paddingTop={true}
                            item={artwork}
                            smWidth={50} />
                    </div>
                );
            });
        });

        const exhibitions = util.try(() => {
            return this.props.page.acf.featured_exhibitions.map((exhibition, i) => {
                return (
                    <Exhibition
                        key={i}
                        item={exhibition} />
                );
            });
        });

        const news = util.try(() => {
            return this.props.news.map((article, i) => {
                return (
                    <News key={i} item={article} isHomepage={true} />
                );
            });
        });

        const twitter = util.try(() => this.props.page.acf.twitter_handle) && util.try(() => this.props.twitter[0]);
        const instagram = util.try(() => this.props.page.acf.instagram_handle) && util.isArray(this.props.instagram);

        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)} />

                {/* Featured Artworks */}
                <section className='mb6 sm-mb7'>
                    <div className='relative clearfix sm-mbn6'>
                        <div className='sm-show absolute top-0 bottom-0 left-0 col-6 border-right border-grey-002 mb6' />
                        {artworks}
                    </div>
                </section>

                {/* Statement */}
                {util.try(() => this.props.page.acf.statement) &&
                    <section className='clearfix px4 pb6 md-px6 sm-pb7'>
                        <div className='mb2 line-height-0'>–</div>
                        <h3 className='m0 mb6 sm-mb7'>Statement</h3>
                        <div className='col-12 sm-col-10 limit-line-length-2'>
                            <span
                                className='h2 text-bg-grey-002'
                                dangerouslySetInnerHTML={{__html: this.props.page.acf.statement}} />
                        </div>
                    </section>
                }

                {/* News & Exhibitions */}
                <section className='relative clearfix'>
                    <div className='sm-show absolute top-0 bottom-0 left-0 col-6 border-right border-grey-002' />
                    <div className={`relative col col-12 sm-col-6 ${!instagram && 'pb4 sm-pb6'}`}>
                        <div className='px4 md-px6'>
                            <div className='mb2 line-height-0'>–</div>
                            <h3 className='m0 mb6 sm-mb7'>Featured Exhibitions</h3>
                        </div>
                        {/* Exhibitions */}
                        <div className='clearfix mtn4 sm-mtn6'>
                            {exhibitions}
                        </div>
                        {/* Twitter */}
                        {twitter &&
                            <div className='px4 md-px6'>
                                <Twitter
                                    handle={this.props.page.acf.twitter_handle}
                                    tweet={this.props.twitter[0]} />
                            </div>
                        }
                    </div>
                    <div className={`relative col col-12 sm-col-6 ${!instagram && 'pb4 sm-pb6'}`}>
                        <div className='px4 md-px6'>
                            <div className='mb2 line-height-0'>–</div>
                            <h3 className='m0 mb6 sm-mb7'>Latest News</h3>
                        </div>
                        {/* News */}
                        <div>
                            {util.try(() => news[0]) &&
                                <div className='mtn4 sm-mtn6'>
                                    {news[0]}
                                </div>
                            }
                            {/* Newsletter */}
                            <div className='p4 md-p6'>
                                <div className='mb2 line-height-0'>–</div>
                                <h3 className='m0 limit-line-length'>Newsletter</h3>
                                <div className='mb4 limit-line-length'>
                                    Stay up to date with the latest news and updates from El Anatsui
                                </div>
                                <Newsletter />
                            </div>
                            {util.try(() => news[1])}
                        </div>
                    </div>
                </section>

                {/* Instagram */}
                {instagram &&
                    <Instagram
                        handle={this.props.page.acf.instagram_handle}
                        posts={this.props.instagram} />
                }

                <div className='border-top border-grey-002 not-empty' />

                <Footer menu={util.try(() => this.props.settings.footer)} />

            </Page>
        );

    }
};

module.exports = Home;
