import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import moment from 'moment';
import util from '../../utilities';


class artworksDetail extends React.Component {

    render() {
        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />

                <section className='border-bottom border-grey-002 py4 sm-p0 sm-pt7'>
                    <div className='relative clearfix'>

                        {/* Title */}
                        <div className='relative col-12 sm-col-6 px4 md-px6 sm-border-right border-grey-002'>
                            <h1
                                className='mt3 mb3 sm-mt0 sm-mb0 line-height-3'
                                dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                        </div>
                        <div
                            className='relative col col-12 sm-col-6 px4 md-px6 sm-pb7 sm-border-right border-grey-002'
                            dangerouslySetInnerHTML={{__html: util.try(() => moment(this.props.post.date).format('DD MMM YYYY'))}} />

                        {/* Image counter */}
                        <div className='relative col col-6 px4 md-px6 mb3 sm-mb0 sm-pb6'>
                            {`Duration: ${this.props.post.acf.duration}`}
                        </div>
                    </div>
                </section>

                <section>
                    <div
                        className='oembed'
                        dangerouslySetInnerHTML={{__html: this.props.post.acf.video}} />
                </section>

                <section className='relative clearfix pt6 sm-pt7 border-bottom border-grey-002'>
                    <div className='mt7 absolute left-0 right-0 top-0 bottom-0 col-6 sm-show border-right border-grey-002' />

                    <div className='col col-12 sm-col-6 col px4 pb6 md-px6 sm-pb7 border-bottom border-grey-002 sm-border-0 limit-line-length'>
                        <span className='h1 text-bg-grey-002'>
                            {this.props.post.acf.standfirst}
                        </span>
                    </div>

                    <div className='col col-12 sm-col-6 col-right px4 py6 sm-p0 sm-px4 md-px6 sm-pb7'>
                        <div
                            className='rte limit-line-length'
                            dangerouslySetInnerHTML={{
                                __html: util.try(() => this.props.post.acf.editorial)
                            }} />
                    </div>
                </section>

                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />

            </Page>
        );
    }
}

module.exports = artworksDetail;
