import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Carousel from '../../partials/images/carousel.jsx';
import Series from '../../partials/artworks/related-series.jsx';
import moment from 'moment';
import util from '../../utilities';


class artworksDetail extends React.Component {

    constructor() {
        super();
        this.handleNavigate = this.handleNavigate.bind(this);
        this.state = {
            currentFrame: 0
        };
    }

    handleNavigate(direction) {
        let i = this.state.currentFrame + direction;
        if (i < 0) {
            i = this.props.post.acf.images.length - 1;
        } else if (i > this.props.post.acf.images.length - 1) {
            i = 0;
        }
        this.setState({currentFrame: i});
    }

    render() {

        const caption = util.try(() => this.props.post.acf.images[this.state.currentFrame].caption);

        let totalImages = util.try(() => this.props.post.acf.images.length) || 0;

        let date = util.try(() => moment(this.props.post.date).get('year'));
        if (util.try(() => this.props.post.acf.start_date)) {
            date = `${moment(this.props.post.acf.start_date, 'YYYYMMDD').get('year')} - ${date}`;
        }

        let series = [];
        if (util.isArray(this.props.series)) {
            this.props.series.forEach((item, i) => {
                if (util.isArray(item.artworks)) {
                    series.push(
                        <Series
                            key={i}
                            index={i}
                            series={item}
                            artworks={item.artworks} />
                    );
                }
            });
        }

        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />

                <section className='py6 sm-p0 sm-pt7'>
                    <div className='relative clearfix'>

                        {/* Title */}
                        <div className='col-12 sm-col-6 px4 md-px6'>
                            <h1
                                className='m0 mb2 line-height-3'
                                dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                        </div>
                        <div
                            className='relative col col-12 sm-col-6 px4 md-px6 sm-pb7'
                            dangerouslySetInnerHTML={{__html: date}} />

                        {totalImages > 1 && (
                            <div>
                                {/* Image counter */}
                                <div className='relative col col-6 sm-col-3 px4 sm-p0 sm-px4 md-px6 sm-pb6 sm-border-left sm-border-right border-grey-002'>
                                    <div className='sm-hide py2'>—</div>
                                    <div className=''>
                                        {`Image ${this.state.currentFrame + 1} of ${totalImages}`}
                                    </div>
                                </div>

                                {/* Carousel buttons */}
                                <div className='relative col col-6 sm-col-3 px4 md-p0 md-px6'>
                                    <div className='sm-hide py2'>—</div>
                                    <button
                                        className='button p0 pr2'
                                        onClick={this.handleNavigate.bind(this, -1)}>
                                        <i className='icon-arrow-left' />
                                    </button>
                                    <button
                                        className='button p0 pl2'
                                        onClick={this.handleNavigate.bind(this, 1)}>
                                        <i className='icon-arrow-right' />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                {/* Carousel */}
                <Carousel
                    currentFrame={this.state.currentFrame}
                    images={util.try(() => this.props.post.acf.images)} />

                <section className='relative clearfix pt6 sm-pt7 border-bottom border-grey-002'>
                    <div className='mt7 absolute left-0 right-0 top-0 bottom-0 col-6 sm-show border-right border-grey-002' />
                    {/* Artwork data */}
                    <div className='col col-12 sm-col-6 col px4 pb6 md-px6 sm-pb7 border-bottom border-grey-002 sm-border-0'>
                        <div className='bold'>Data</div>
                        <div>
                            {util.try(() => (
                                <div>{this.props.post.acf.media}</div>
                            ))}
                            {util.try(() => (
                                <div>{this.props.post.acf.dimensions}</div>
                            ))}
                        </div>
                        {/* Artwork type */}
                        {util.try(() => (
                            <div>
                                <div>—</div>
                                <div>
                                    {this.props.post.artwork_type[0].name}
                                </div>
                            </div>
                        ))}
                        {/* Image caption */}
                        {util.try(() => (
                            <div>
                                <div>—</div>
                                <div>Photo:</div>
                                <div>{caption}</div>
                            </div>
                        ))}
                    </div>

                    {/* Artwork description */}
                    {util.try(() => this.props.post.acf.description) && (
                        <div className='col col-12 sm-col-6 col-right px4 py6 sm-p0 sm-px4 md-px6 sm-pb7'>
                            <div
                                className='rte limit-line-length'
                                dangerouslySetInnerHTML={{
                                    __html: util.try(() => this.props.post.acf.description)
                                }} />
                        </div>
                    )}
                </section>

                {/* Related series */}
                {series}

                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />

            </Page>
        );
    }
}

module.exports = artworksDetail;
