/*
 * Artworks Finder
 */

import React from 'react';
import qs from 'query-string';
import util from '../../utilities';


/**
 * A Finder tab button
 * @prop {string} label
 * @prop {number} index
 * @prop {number} active
 * @prop {function} onClick
 */
const TabButton = (props) => {
    const isActive = props.index === props.active;
    const bgColour = (isActive) ? 'bg-blue-001' : 'bg-blue-002';
    const border = (!isActive && (props.index + 1 !== props.active) && (props.index + 1) % 3 !== 0) ?
        'border-right border-blue-001' :
        '';
    const margin = (!isActive && (props.index - 1 !== props.active) && props.index > 0) ? 'mtn4 sm-m0' : '';
    const colWidth = util.try(() => props.alignCol) ? 'sm-col-3' : 'sm-col-4';
    return (
        <button
            onClick={props.onClick.bind(null, props.index)}
            className={`button border-0 left-align col col-12 ${colWidth} p0 pt4 sm-pt6 ${bgColour} ${margin}`}>
            <div className='relative'>
                <div className='px4 md-px6 pb4 sm-pb5'>
                    <div className='mb2 line-height-0'>–</div>
                    <span
                        className='truncate'
                        dangerouslySetInnerHTML={{__html: props.label}} />
                    {!isActive &&
                        <i className='ml2 icon-arrow-down' />
                    }
                </div>
                <div className={`absolute right-0 top-0 bottom-0 ${border}`} />
            </div>
        </button>
    );
};


/**
 * A Finder tab
 * @prop {string} name
 * @prop {array} options
 * @prop {array} value
 * @prop {boolean} isActive
 * @prop {function} onSelect
 * @prop {string} submitURL
 */
const Tab = (props) => {

    const options = props.options.map((option, i) => {
        let clearfixes = [];
        if (i > 0 && (i + 1) % 4 === 0) {
            clearfixes.push(<div key='m' className='md-show clearfix pb2' />);
        }
        if (i > 0 && (i + 1) % 3 === 0) {
            clearfixes.push(<div key='sm' className='sm-show md-hide clearfix pb2' />);
        }
        if (i > 0 && (i + 1) % 2 === 0) {
            clearfixes.push(<div key='md' className='sm-hide clearfix pb2' />);
        }
        const selected = props.value.find((val) => val === option.value);
        const colours = (selected) ? 'white bg-blue-003 hover-opacity-85' : 'bg-white hover-opacity-80';
        return (
            <span key={i}>
                <div className='col col-6 sm-col-4 md-col-3 px4 md-px6'>
                    <button
                        onClick={props.onSelect.bind(null, props.name, option.value)}
                        className={`btn border-0 px4 py2 left-align col-12 ${colours}`}>
                        {selected &&
                            <div className='right'>
                                ×
                            </div>
                        }
                        <div
                            className='overflow-hidden'
                            dangerouslySetInnerHTML={{__html: option.label || ''}} />
                    </button>
                </div>
                {clearfixes}
            </span>
        );
    });

    return (
        <div className={`${(props.isActive) ? 'show' : 'hide'} relative bg-blue-001`}>
            <div className='clearfix pb4 sm-py6'>
                {options}
            </div>
            <div className='clearfix pb4 sm-p0'>
                <div className='col col-12 sm-col-4 md-col-3 col-right px4 md-px6'>
                    <a
                        href={props.submitURL}
                        className='block btn bg-black white hover-opacity-90 border-0 px4 py2 left-align col-12'>
                        <div className='right'>
                            <i className='icon-arrow-right' />
                        </div>
                        <div className='overflow-hidden'>Go</div>
                    </a>
                </div>
            </div>
        </div>
    );

};


/**
 * Class representing the Artworks Finder
 * @extends React.component
 * @prop {object} filters
 * @prop {number} active
 * @prop {boolean} alignCol
 */
class Finder extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.buildSubmitURL = this.buildSubmitURL.bind(this);
        this.state = {
            active: this.props.active,
            filters: this.props.filters || {}
        };
    }

    /**
     * Toggle tabs
     * @param {number} next
     */
    toggle(next) {
        if (this.state.active === next) {
            this.setState({
                active: null
            });
        } else {
            this.setState({
                active: next
            });
        }
    }

    /**
     * Select
     * @param {string} key
     * @param {string} value
     */
    handleSelect(key, value) {
        let filters = this.state.filters;
        if (filters[key]) {
            filters[key].value = (Array.isArray(filters[key].value)) ? filters[key].value : [];
            if (filters[key].value.find((val) => val === value)) {
                filters[key].value = filters[key].value.filter((val) => val !== value);
            } else {
                filters[key].value.push(value);
            }
        }
        this.setState({
            filters: filters
        });
    }

    /**
     * Build submit URL
     * @returns {string}
     */
    buildSubmitURL() {
        let query = null;
        util.try(() => {
            Object.keys(this.state.filters).forEach((key) => {
                if (util.isArray(this.state.filters[key].value)) {
                    query = query || {};
                    query[key] = this.state.filters[key].value;
                }
            });
        });
        query = qs.stringify(query);
        return `/${util.try(() => this.props.baseUrl)}${query && '?'}${query}`;
    }

    render() {

        const submitURL = this.buildSubmitURL();

        const tabButtons = util.try(() => {
            return Object.keys(this.state.filters).map((key, i) => {
                const filter = this.state.filters[key];
                return (
                    <TabButton
                        key={`button-${i}`}
                        index={i}
                        active={this.state.active}
                        label={filter.label}
                        onClick={this.toggle}
                        alignCol={util.try(() => this.props.alignCol)} />
                );
            });
        });

        const tabs = util.try(() => {
            return Object.keys(this.state.filters).map((key, i) => {
                const filter = this.state.filters[key];
                return (
                    <span key={`tab-${i}`}>
                        <div className='clearfix sm-hide'>
                            {tabButtons[i]}
                        </div>
                        <Tab
                            name={key}
                            options={filter.options}
                            value={filter.value}
                            isActive={this.state.active === i}
                            onSelect={this.handleSelect}
                            submitURL={submitURL} />
                    </span>
                );
            });
        });

        return (
            <section className='bg-blue-002'>
                {/* Tab buttons */}
                <div className='sm-show relative clearfix'>
                    {tabButtons}
                </div>
                {/* Current Tab */}
                <div className='relative clearfix'>
                    {tabs}
                </div>
            </section>
        );
    }
};

module.exports = Finder;
