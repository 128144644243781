/*
 * Related Series
 */

import React from 'react';
import Artwork from '../listings/artworks/item.jsx';
import util from '../../utilities';


/**
 * Class representing the Related Series module
 * @extends React.component
 * @prop {object} series
 * @prop {array} artworks
 * @prop {number} index
 */
class Series extends React.Component {
    render() {

        const artworks = util.try(() => {
            return this.props.artworks.map((item, i) => {
                const clearfix = (i > 0 && (i + 1) % 3 === 0) ? (
                    <div className='sm-show clearfix' />
                ) : null;
                return (
                    <span key={item.id}>
                        <div className='col col-12 sm-col-4'>
                            <Artwork
                                item={item}
                                index={i} />
                        </div>
                        {clearfix}
                    </span>
                );
            });
        });

        let seriesTitle = '';
        seriesTitle = (util.try(() => this.props.series.title.rendered) || util.try(() => this.props.series.post_title)) + ' Series';

        return (
            <section>
                <div className='md-p6 px4 pb4 md-px6 pt5 sm-pt6 sm-mt2'>
                    <div className=''>–</div>
                    <h3
                        className='m0'
                        dangerouslySetInnerHTML={{
                            __html: seriesTitle
                        }} />
                    <div>
                        <a
                            href={util.try(() => this.props.series.link) || `/series/${util.try(() => this.props.series.slug)}`}
                            className='link-underline'>View All
                        </a>
                    </div>
                </div>
                {artworks &&
                    <div className='relative border-bottom border-grey-002'>
                        <div className='sm-show mt6 absolute top-0 bottom-0 left-0 right-0'>
                            <div className='col col-4 height-100 border-right border-grey-002' />
                            <div className='col col-4 height-100 border-right border-grey-002' />
                        </div>
                        <div className='relative clearfix'>
                            {artworks}
                        </div>
                    </div>
                }
            </section>
        );

    }
};

module.exports = Series;
