/*
 * AJAX listing
 */

import React from 'react';
import qs from 'query-string';
import diff from 'deep-diff';


/**
 * Class representing an AJAX listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 */
class AjaxListing extends React.Component {

    /**
     * Setup
     */
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.handleData = this.handleData.bind(this);
        this.state = {
            items: props.items || [],
            status: 200,
            loading: false
        };
    }

    /**
     * Trigger a load if the path or query has changed
     * @param {object} nextProps
     */
    componentWillUpdate(nextProps, nextState) {
        if (diff(nextProps.query, this.props.query) ||
            diff(nextProps.path, this.props.path)) {
            this.load();
        }
    }

    /**
     * Load new content
     */
    load() {
        this.setState({
            loading: true
        }, () => {

            /**
             * Build the full URL
             */
            const buildURL = () => {
                const base = this.props.path || '';
                const query = qs.stringify(this.props.query || {});
                return base + '?' + query;
            };

            const url = buildURL();

            // fetch data
            this.loading = fetch(url).then((response) => {
                if (response.status !== 200) {
                    this.setState({
                        loading: false,
                        status: response.status
                    });
                    return false;
                } else {
                    return response.json();
                }
            }).then((data) => {
                this.handleData(data, () => {
                    this.setState({
                        loading: false,
                        status: 200
                    });
                    if (typeof this.props.onLoad === 'function') {
                        this.props.onLoad(data);
                    }
                });
            }).catch((err) => {
                this.setState({
                    status: 500
                });
            });

        });
    }

    /**
     * Placeholder method to handle response
     * @param {object} data
     */
    handleData(data, callback) {
        this.setState({
            items: data.posts
        }, () => {
            callback();
        });
    }

}

module.exports = AjaxListing;
