/*
 * App
 */

import React from 'react';
import pages from '../pages';


/**
 * Class representing the application
 * @extends React.component
 */
class App extends React.Component {
    render() {

        let Content;

        if (this.props.pageComponent &&
            typeof(pages[this.props.pageComponent]) !== 'undefined') {
            const Component = pages[this.props.pageComponent];
            Content = (
                <Component {...this.props} />
            );
        }

        return (
            <main className='height-100'>
                {Content}
            </main>
        );
    }

};

module.exports = App;
