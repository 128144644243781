/*
 * Search page
 */

import React from 'react';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Listing from '../partials/listings/search/index.jsx';
import util from '../utilities';


/**
 * Class representing the search page
 * @extends React.component
 * @prop {object} post
 * @prop {object} settings
 * @prop {object} items
 * @prop {string} type
 */
class SearchPage extends React.Component {
    render() {

        const count = util.try(() => this.props.items.total) || 0;

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    term={this.props.term} />
                <Listing
                    page={util.try(() => this.props.items.currentPage) || 1}
                    total={util.try(() => this.props.items.total)}
                    completeTotal={util.try(() => this.props.items.completeTotal)}
                    totalPages={util.try(() => this.props.items.totalPages)}
                    items={util.try(() => this.props.items.posts) || []}
                    term={this.props.term}
                    type={this.props.type} />
                <Footer menu={util.try(() => this.props.settings.footer)} />
            </Page>
        );
    }
};

module.exports = SearchPage;
