module.exports = {
    home: require('./home.jsx'),
    artworksListing: require('./artworks/listing.jsx'),
    artworksDetail: require('./artworks/detail.jsx'),
    seriesListing: require('./series/listing.jsx'),
    seriesDetail: require('./series/detail.jsx'),
    videosListing: require('./videos/listing.jsx'),
    videosDetail: require('./videos/detail.jsx'),
    editorial: require('./editorial.jsx'),
    collectionsListing: require('./collections/listing.jsx'),
    exhibitionsListing: require('./exhibitions/listing.jsx'),
    exhibitionsDetail: require('./exhibitions/detail.jsx'),
    mapListing: require('./map.jsx'),
    newsDetail: require('./news/detail.jsx'),
    newsListing: require('./news/listing.jsx'),
    statements: require('./statements.jsx'),
    bibliography: require('./bibliography.jsx'),
    search: require('./search.jsx'),
    err: require('./err.jsx')
};
