/*
 * News listing container
 */

import React from 'react';
import Filter from '../../ui/dropdown-filter.jsx';
import ListingContainer from '../base/container.jsx';
import Pagination from '../shared/pagination.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing an News listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class NewsListingContainer extends ListingContainer {

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.filters = props.filters;
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            totalPages: data.totalPages
        });
    }

    render() {


        return (
            <section className='relative'>

                {/* Title */}
                <section className='clearfix border-bottom border-grey-002 sm-mb7'>
                    <div className='col col-12'>
                        <div className='col col-6 m0 px4 md-px6 py6 sm-mt2 md-mt0 md-py7'>
                            <h2 className='mt0 mb0 line-height-3'>News</h2>
                        </div>
                        <div className='relative col-right col-12 sm-col-6 clearfix'>
                            <div className='col col-12 sm-col-6 px4 md-px6 sm-mt0 sm-py6 md-py6 md-my2'>
                                <Filter
                                    type='category'
                                    current={util.try(() => this.state.filters.category.value)}
                                    onSelect={this.handleFilter}
                                    options={util.try(() => this.state.filters.category.options)} />
                            </div>
                            <div className='col col-12 sm-col-6 px4 md-px6 mt2 mb5 sm-my0 sm-py6 md-py6 md-my2'>
                                <Filter
                                    type='year'
                                    current={util.try(() => this.state.filters.year.value)}
                                    onSelect={this.handleFilter}
                                    options={util.try(() => this.state.filters.year.options)} />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Listing */}
                <Listing
                    path='/api/news'
                    items={this.props.items}
                    query={{
                        page: this.state.page,
                        category: util.try(() => this.state.filters.category.value),
                        year: util.try(() => this.state.filters.year.value)
                    }}
                    onLoad={this.handleLoad} />

                {/* Pagination */}
                <Pagination
                    current={this.state.page}
                    total={this.state.totalPages}
                    navigate={this.handlePage} />

            </section>
        );

    }
}

module.exports = NewsListingContainer;
