/*
 * Bibliography listing
 */

import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import ListingItem from './item.jsx';
import Message from '../shared/message.jsx';
import Loading from '../shared/loading.jsx';
import util from '../../../utilities';


/**
 * Class representing an Bibliography listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 * @prop {number} featured
 * @prop {boolean} morePages
 */
class BibliographyListing extends AjaxListing {

    /**
     * Render
     */

    render() {

        const items = util.try(() => {
            return this.state.items.map((item, i) => {
                return (
                    <span key={item.id}>
                        <ListingItem
                            item={item}
                            index={i} />
                    </span>
                );
            });
        });

        return (
            <div>

                {/* Listing */}
                {this.state.status === 200 && this.state.items.length > 0 &&
                    <section className='relative'>
                        <div className='relative clearfix'>
                            {items}
                        </div>
                        {this.props.morePages &&
                            <Loading active={this.state.loading} />
                        }
                    </section>
                }

                {/* Messages */}
                {this.state.status === 200 && this.state.items.length === 0 &&
                    <div className='clearfix border-bottom border-grey-002'>
                        <Message message='Sorry, no bibliographies found. Please try a wider search.' />
                    </div>
                }
                {this.state.status !== 200 &&
                    <div className='clearfix border-bottom border-grey-002'>
                        <Message message='Sorry, something went wrong. Please try again later.' />
                    </div>
                }


            </div>
        );

    }

}

module.exports = BibliographyListing;
