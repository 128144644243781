/*
 * Search listing
 */

import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import Message from '../shared/message.jsx';
import Loading from '../shared/loading.jsx';
import Artwork from '../artworks/item.jsx';
import Item from './item.jsx';
import util from '../../../utilities';


/**
 * Class representing Search listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 * @prop {number} featured
 * @prop {boolean} morePages
 * @prop {string} type - only updated after load
 */
class SearchListing extends AjaxListing {

    /**
     * Render
     */
    render() {

        let items;
        let grid;

        if (this.props.type === 'artwork') {

            // Artworks
            items = util.try(() => {
                return this.state.items.map((item, i) => {
                    const clearfix = (i > 0 && (i + 1) % 3 === 0) ? (
                        <div className='sm-show clearfix' />
                    ) : null;
                    return (
                        <span key={item.id}>
                            <div className='col col-12 sm-col-4'>
                                <Artwork
                                    item={item}
                                    index={i}
                                    paddingTop={true} />
                            </div>
                            {clearfix}
                        </span>
                    );
                });
            });

            grid = (
                <div className='sm-show mt6 absolute top-0 bottom-0 left-0 right-0'>
                    <div className='col col-4 height-100 border-right border-grey-002' />
                    <div className='col col-4 height-100 border-right border-grey-002' />
                </div>
            );

        } else {

            // All
            items = util.try(() => {
                return this.state.items.map((item, i) => {
                    return (
                        <Item
                            key={item.id}
                            item={item}
                            index={i} />
                    );
                });
            });

        }

        return (
            <div>

                {/* Listing */}
                {(this.state.status === 200 && this.state.items.length > 0) &&
                    <section className='relative border-bottom border-grey-002'>
                        {grid}
                        <div className='clearfix'>
                            {items}
                        </div>
                        {this.props.morePages &&
                            <Loading active={this.state.loading} />
                        }
                    </section>
                }

                {/* Messages */}
                {this.state.status === 200 && this.state.items.length === 0 &&
                    <div className='clearfix border-bottom border-grey-002'>
                        <Message message='Sorry, no results found. Please try a different search term.' />
                    </div>
                }
                {this.state.status !== 200 &&
                    <div className='clearfix border-bottom border-grey-002'>
                        <Message message='Sorry, something went wrong. Please try again later.' />
                    </div>
                }

                {/* Mask */}
                {this.state.loading &&
                    <div className='bg-darken-1 z2 absolute top-0 bottom-0 left-0 right-0' />
                }

            </div>
        );

    }

}

module.exports = SearchListing;
