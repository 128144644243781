/*
 * Artwork listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Artwork listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class ArtworksItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        let date;
        if (util.try(() => this.props.item.date)) {
            date = moment(this.props.item.date).format('MMMM YYYY');
        }

        let bgColor = '';
        if (this.props.index && ((this.props.index + 1) % 2 === 0)) {
            bgColor = ' bg-grey-000';
        }

        return (

            <div className={'col-12 clearfix py5 sm-py4 md-py5' + bgColor}>
                <div className='col col-12 sm-col-3 px4 md-px6' dangerouslySetInnerHTML={{__html: date}} />
                <div className='col col-12 sm-col-3 px4 md-px6'>
                    {util.try(() => this.props.item.bibliography_category[0]) &&
                        <div dangerouslySetInnerHTML={{__html: this.props.item.bibliography_category[0].name}} />
                    }
                </div>
                <div className='col col-12 sm-col-6 px4 md-px6 sm-mb1'>
                    <h3 className='sm-mt0' dangerouslySetInnerHTML={{__html: util.try(() => this.props.item.title.rendered)}} />
                    <div dangerouslySetInnerHTML={{__html: util.try(() => this.props.item.acf.references)}} />
                </div>
            </div>

        );

    }

}

module.exports = ArtworksItem;
