/*
 * Error page
 */

import React from 'react';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import util from '../utilities';


/**
 * Class representing the error pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 * @prop {object} error
 */
class ErrorPage extends React.Component {
    render() {
        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)} />
                <section className='border-bottom border-grey-002 px4 py6 md-px6 sm-py7'>
                    <div className='limit-line-length'>
                        <h2
                            className='h2 m0 text-bg-grey-002'
                            dangerouslySetInnerHTML={{
                                __html: util.try(() => this.props.err.message) ||
                                'Sorry, something went wrong. Please try again later.'
                            }} />
                    </div>
                </section>
                <Footer menu={util.try(() => this.props.settings.footer)} />
            </Page>
        );
    }
};

module.exports = ErrorPage;
