/*
 * Editorial page
 */

import React from 'react';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import PageMenu from '../partials/global/page-menu.jsx';
import ContentModules from '../partials/editorial/modules.jsx';
import util from '../utilities';


/**
 * Class representing the editorial pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 */
class EditorialPage extends React.Component {
    render() {
        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={`/${util.try(() => this.props.post.uri) || ''}`} />


                <section className='border-bottom border-grey-002'>
                    <div className='p4 md-px6 sm-py7 col-12 sm-col-6'>
                        <h1
                            className='mt3 mb3 sm-mt0 sm-mb0 line-height-3'
                            dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                    </div>
                </section>

                <ContentModules modules={util.try(() => this.props.post.acf.modules)} />

                <Footer menu={util.try(() => this.props.settings.footer)} />

            </Page>
        );
    }
};

module.exports = EditorialPage;
