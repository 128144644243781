/**
 * Page menu
 */

import React from 'react';
import util from '../../utilities';


/**
 * Page menu item
 * @prop {string} label
 * @prop {string} url
 * @prop {number} index
 * @prop {boolean} current
 */
const MenuItem = (props) => {
    const border = (!props.current && props.index % 6 !== 0) ? 'border-right border-white' : '';
    const mask = (props.current && props.index > 1) ? 'mln1 border-left border-grey-001' : '';
    return (
        <a
            href={props.url}
            style={{width: '20%'}}
            className={`relative block col ${props.current && 'bg-white'} ${mask} pt4`}>
            <div className='relative'>
                <div className={`absolute right-0 top-0 bottom-0 ${border}`} />
                <div className='relative px4 md-px6 pb4'>
                    <div className='truncate'>
                        <span
                            className={props.current ? '' : 'link-underline'}
                            dangerouslySetInnerHTML={{__html: props.label}} />
                    </div>
                </div>
            </div>
        </a>
    );
};


/**
 * Class representing the page menu
 * @extends React.component
 * @prop {object} menu
 * @prop {string} current
 */
class PageMenu extends React.Component {
    render() {

        if (!this.props.menu || !util.isArray(this.props.menu.children) || !this.props.current) {
            return (
                <div className='border-bottom border-grey-002 not-empty' />
            );
        }

        // Create the menu items
        let items = [];
        util.try(() => {
            this.props.menu.children.map((item, i) => {
                if (i <= 5) {
                    items.push((
                        <MenuItem
                            key={i}
                            label={item.title}
                            url={item.url}
                            index={i + 1}
                            current={this.props.current === item.url} />
                    ));
                }
            });
        });

        const current = util.try(() => {
            return this.props.menu.children.find((item) => {
                return item.url === this.props.current;
            });
        });

        return (
            <span>
                <section className='border-top border-grey-002'>
                    {/* Mobile */}
                    {current &&
                        util.try(() => this.props.menu.url) &&
                        util.try(() => this.props.menu.title) &&
                        <div className='sm-hide border-bottom border-grey-002 p4'>
                            <a className='link' href='/'>Home</a>
                            <i className='icon-arrow-right px2' />
                            <a
                                className='link'
                                href={this.props.menu.uri}
                                dangerouslySetInnerHTML={{__html: this.props.menu.title}} />
                        </div>
                    }
                    {/* Desktop */}
                    <div className='sm-show bg-grey-001 clearfix'>
                        {items}
                    </div>
                </section>
            </span>
        );
    }
}


module.exports = PageMenu;
