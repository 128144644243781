/**
 * Editorial content modules
 */

import React from 'react';
import Image from '../images/image.jsx';
import Framed from '../images/framed.jsx';
import Newsletter from '../global/newsletter.jsx';
import util from '../../utilities';


/**
 * Class representing the Editorial content modules
 * @extends React.component
 * @prop {array} modules
 */
class ContentModules extends React.Component {
    render() {

        let modules;
        if (util.isArray(this.props.modules)) {
            modules = this.props.modules.map((module, i) => {
                let result;
                switch(module.acf_fc_layout) {
                case 'text':
                    // Text
                    result = (
                        <section
                            key={i}
                            className='mt6 mb6 sm-mt7 sm-mb7'>
                            <div className='clearfix'>
                                <div className='col col-12 sm-col-6 px4 md-px6'>
                                    <div className='not-empty' />
                                    {module.pull_quote &&
                                        <div className='limit-line-length mb6 sm-m0'>
                                            <span
                                                className='h2 text-bg-grey-002'
                                                dangerouslySetInnerHTML={{
                                                    __html: module.pull_quote
                                                }} />
                                            {module.pull_quote_credit &&
                                                <div>
                                                    <div className='mt4 mb2 line-height-0'>–</div>
                                                    <div
                                                        className='h7 md-h6'
                                                        dangerouslySetInnerHTML={{
                                                            __html: module.pull_quote_credit
                                                        }} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className='col col-12 sm-col-6 px4 md-px6'>
                                    <div
                                        className='rte limit-line-length'
                                        dangerouslySetInnerHTML={{__html: module.body}} />
                                </div>
                            </div>
                        </section>
                    );
                    break;
                case 'images':
                    if (util.try(() => module.images.length < 2)) {
                        // Single Image
                        result = (
                            <section
                                key={i}
                                className='mt6 mb6 sm-mt7 sm-mb7'>
                                <div className='bg-grey-001 sm-show'>
                                    <Framed image={util.try(() => module.images[0])} />
                                </div>
                                <div className='bg-grey-001 sm-hide'>
                                    <Image image={util.try(() => module.images[0])} />
                                </div>
                                {util.try(() => module.images[0].caption) &&
                                    <div className='px4 md-px6'>
                                        <div className='mt4 mb2 line-height-0'>–</div>
                                        <div
                                            className='h7 md-h6'
                                            dangerouslySetInnerHTML={{
                                                __html: module.images[0].caption
                                            }} />
                                    </div>
                                }
                            </section>
                        );
                    } else {
                        // Image pair
                        result = (
                            <section
                                key={i}
                                className='mt6 mb6 sm-mt7 sm-mb7'>
                                <div className='clearfix'>
                                    <div className='col col-12 sm-col-6 mb6 sm-m0 px4 md-px6'>
                                        <Image
                                            smWidth={50}
                                            image={util.try(() => module.images[0])} />
                                        {util.try(() => module.images[0].caption) &&
                                            <div>
                                                <div className='mt4 mb2 line-height-0'>–</div>
                                                <div
                                                    className='h7 md-h6'
                                                    dangerouslySetInnerHTML={{
                                                        __html: module.images[0].caption
                                                    }} />
                                            </div>
                                        }
                                    </div>
                                    <div className='col col-12 sm-col-6 px4 md-px6'>
                                        <Image image={util.try(() => module.images[1])} />
                                        {util.try(() => module.images[1].caption) &&
                                            <div>
                                                <div className='mt4 mb2 line-height-0'>–</div>
                                                <div
                                                    className='h7 md-h6'
                                                    dangerouslySetInnerHTML={{
                                                        __html: module.images[1].caption
                                                    }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        );
                    }
                    break;
                case 'newsletter':
                    // Newsletter
                    result = (
                        <section
                            key={i}
                            className='mt6 mb6 sm-mt7 sm-mb7'>
                            <div className='clearfix'>
                                <div className='col col-12 sm-col-6 px4 md-px6'>
                                    {module.text &&
                                        <div
                                            className='mb4 limit-line-length'
                                            dangerouslySetInnerHTML={{__html: module.text}} />
                                    }
                                    <Newsletter />
                                </div>
                            </div>
                        </section>
                    );
                    break;
                default:
                    result = (<span key={i} />);
                }
                return result;
            });
        }

        return (
            <span>
                {modules &&
                    <div className='clearfix border-bottom border-grey-002'>
                        {modules}
                    </div>
                }
            </span>
        );

    }
}


module.exports = ContentModules;
