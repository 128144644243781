/*
 * Series
 */

import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import ArtworkListingItem from '../../partials/listings/artworks/item.jsx';
import util from '../../utilities';


/**
 * Class representing the Series detail page
 * @extends React.component
 * @prop {object} post
 * @prop {object} artworks
 */
class SeriesDetail extends React.Component {

    render() {

        const artworks = util.try(() => {
            return this.props.artworks.posts.map((artwork, i) => {
                const clearfix = (i > 0 && i + 1 % 3 === 0) ? (
                    <div className='sm-show clearfix' />
                ) : null;
                return (
                    <span key={artwork.id}>
                        <div className='col col-12 sm-col-4'>
                            <ArtworkListingItem
                                item={artwork}
                                index={i}
                                paddingTop={true} />
                        </div>
                        {clearfix}
                    </span>
                );
            });
        });

        const total = util.try(() => this.props.artworks.total) || 0;

        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />

                {/* Title */}
                <section className='border-bottom border-grey-002 sm-pt7'>
                    <div className='relative clearfix'>
                        <div className='sm-show absolute top-0 bottom-0 left-0 col-6 border-right border-grey-002' />
                        <div className='relative col col-12 sm-col-6 px4 pt4 sm-p0 sm-px4 md-px6 sm-pb7'>
                            <h1
                                className='mt3 sm-mt0 mb2 line-height-3'
                                dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                            <div dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.acf.time)}} />
                        </div>
                        <div className='relative col col-12 sm-col-6 px4 pb4 sm-p0 sm-px4 md-px6 sm-pb7'>
                            <div
                                className='sm-show h1'
                                dangerouslySetInnerHTML={{__html: '&nbsp'}} />
                            <div
                                className='mb3'
                                dangerouslySetInnerHTML={{
                                    __html: `${total} ${(total === 1) ? 'Artwork' : 'Artworks'}`
                                }} />
                        </div>
                    </div>
                </section>

                {/* Description */}
                <section className='clearfix border-bottom border-grey-002'>
                    {util.try(() => this.props.post.acf.standfirst) &&
                        <div className='col col-12 sm-col-6 px4 pt6 md-px6 sm-py7'>
                            <div className='limit-line-length'>
                                <span
                                    className='h2 text-bg-grey-002'
                                    dangerouslySetInnerHTML={{__html: this.props.post.acf.standfirst}} />
                            </div>
                        </div>
                    }
                    <div className='col col-12 sm-col-6 col-right px4 py6 md-px6 sm-py7'>
                        <div
                            className='rte limit-line-length'
                            dangerouslySetInnerHTML={{
                                __html: util.try(() => this.props.post.acf.editorial) || ''
                            }} />
                    </div>
                </section>

                {/* Artworks */}
                <section className='relative border-bottom border-grey-002'>
                    <div className='sm-show mt6 absolute top-0 bottom-0 left-0 right-0'>
                        <div className='col col-4 height-100 border-right border-grey-002' />
                        <div className='col col-4 height-100 border-right border-grey-002' />
                    </div>
                    <div className='relative clearfix'>
                        {artworks}
                    </div>
                </section>

                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />

            </Page>
        );

    }

};

module.exports = SeriesDetail;
