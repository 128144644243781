import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Framed from '../../partials/images/framed.jsx';
import Image from '../../partials/images/image.jsx';
import ArtworksListingItem from '../../partials/listings/artworks/item.jsx';
import Map from '../../partials/ui/map.jsx';
import moment from 'moment';
import util from '../../utilities';


class ExhibitionsDetail extends React.Component {

    render() {

        let description;
        if (util.try(() => this.props.post.exhibition_type[0].name)) {
            description = `${this.props.post.exhibition_type[0].name} at ${this.props.post.acf.address}`;
        } else if (util.try(() => this.props.post.acf.address)) {
            description = this.props.post.acf.address;
        }


        let dateRange;
        if (util.try(() => this.props.post.acf.start_date)) {
            dateRange = `${moment(this.props.post.acf.start_date, 'YYYYMMDD').format('DD MMMM YYYY')}`;
            if (util.try(() => this.props.post.acf.end_date)) {
                dateRange += util.try(() => ` - ${moment(this.props.post.acf.end_date, 'YYYYMMDD').format('DD MMMM YYYY')}`);
            }
        }

        const images = util.try(() => {
            return this.props.post.acf.exhibition_images.map((item, i) => (
                <div key={i}>
                    <Framed
                        className='sm-show bg-grey-001'
                        image={item}/>
                    <Image
                        className='sm-hide bg-grey-001'
                        image={item}/>
                    <div className='px4 md-px6 pt2 sm-mb2 pb6'>
                        {item.caption && (
                            <div>
                                <div>—</div>
                                <div className='h6'>
                                    {item.caption}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ));
        });


        const artworks = util.try(() => {
            return this.props.post.acf.artworks_exhibitions.map((item, i) => {
                const clearfix = (i > 0 && (i + 1) % 3 === 0) ? (
                    <div className='sm-show clearfix' />
                ) : null;
                return (
                    <span key={i}>
                        <div className='col col-12 sm-col-4'>
                            <ArtworksListingItem
                                item={item}
                                index={i} />
                        </div>
                        {clearfix}
                    </span>
                );
            });
        });

        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />

                {/* Title */}
                <section className='border-bottom border-grey-002 pt4 pb4 sm-p0 sm-pt7'>
                    <div className='relative clearfix'>
                        <div className='relative col-12 sm-col-6 px4 md-px6 pt3 sm-pt0'>
                            <h1
                                className='m0 mb2 line-height-3'
                                dangerouslySetInnerHTML={{__html: util.try(() => this.props.post.title.rendered)}} />
                        </div>
                        <div className='relative col col-12 sm-col-6 px4 pb3 md-px6 sm-pb7'>
                            {description}
                        </div>
                        <div className='relative col-right col-12 sm-col-6 px4 md-px6 sm-pb6 sm-border-left sm-border-right border-grey-002'>
                            <div>
                                {dateRange}
                            </div>
                            {util.try(() => this.props.post.acf.website) && (
                                <a href={this.props.post.acf.website}>
                                    <div className='bg-black white py2 px4 mt4 sm-mt5 col-12 sm-col-7 md-col-5'>
                                        <div className='right'>
                                            <i className='icon-arrow-right' />
                                        </div>
                                        <span className='overflow-hidden'>
                                            View website
                                        </span>
                                    </div>
                                </a>
                            )}
                        </div>
                    </div>
                </section>

                {/* Listing Image */}
                <Framed
                    className='bg-grey-001 sm-show'
                    image={util.try(() => this.props.post.acf.listing_image)} />
                <Image
                    className='sm-hide'
                    image={util.try(() => this.props.post.acf.listing_image)} />

                {/* Description */}
                <section className='relative clearfix pt6 sm-pt7'>
                    <div className='col col-12 sm-col-6 col px4 pb6 md-px6 sm-pb7 sm-border-0 limit-line-length'>
                        <span className='h1 text-bg-grey-002'>
                            {util.try(() => this.props.post.acf.standfirst)}
                        </span>
                    </div>
                    <div className='col col-12 sm-col-6 col-right px4 pb6 sm-p0 md-px6 sm-pb7'>
                        <div
                            className='rte limit-line-length'
                            dangerouslySetInnerHTML={{
                                __html: util.try(() => this.props.post.acf.editorial)
                            }} />
                    </div>
                </section>

                {/* Exhibition Images */}
                {util.try(() => this.props.post.acf.exhibition_images) && (
                    <section>
                        {images}
                    </section>
                )}

                {/* Map */}
                <section className='border-top border-grey-002'>
                    <div className='p4 md-p6 sm-my2'>
                        <div>—</div>
                        <div className='bold'>
                            Location
                        </div>
                        <div>
                            {util.try(() => this.props.post.acf.address)}
                        </div>
                    </div>
                    <Map
                        location={util.try(() => this.props.post.acf.location)}
                        className='aspect-16-9 sm-aspect-3-1'/>
                </section>

                {/* Exhibited Artworks */}
                {util.try(() => this.props.post.acf.artworks_exhibitions) && (
                    <section>
                        <div className='px4 md-px6 pt5 sm-pt6 sm-mt2 pb2'>
                            <div>—</div>
                            <div className='bold'>
                                Exhibited Artworks
                            </div>
                        </div>
                        <div className='relative border-bottom border-grey-002'>
                            <div className='sm-show mt6 absolute top-0 bottom-0 left-0 right-0'>
                                <div className='col col-4 height-100 border-right border-grey-002' />
                                <div className='col col-4 height-100 border-right border-grey-002' />
                            </div>
                            <div className='relative clearfix'>
                                {artworks}
                            </div>
                        </div>
                    </section>
                )}

                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />

            </Page>
        );
    }
}

module.exports = ExhibitionsDetail;
