
/* global google*/

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import InfoWindow from './info-window.jsx';
import { diff } from 'deep-diff';
import util from '../../utilities';


/**
 * Class representing a Map component
 * @extends React.Component
 * @prop {object} location
 * @prop {number} zoom
 * @prop {string} width
 * @prop {number} height
 * @prop {string} icon
 */
class MapComponent extends React.Component {

    constructor(props) {
        super(props);
        this.makeMarker = this.makeMarker.bind(this);
        this.clearActiveMarker = this.clearActiveMarker.bind(this);
        this.state = {
            locations: props.locations,
            activeMarker: null
        };
    }

    /**
     * Append necessary scripts for clusterer and infoboxes to work
     */
    componentDidMount() {
        if (typeof google === 'undefined' || !google.maps) {
            const appendScript = (src, callback) => {
                let script = document.createElement('script');
                script.src = src;
                script.async = false;
                script.onload = () => callback && callback();
                document.head.appendChild(script);
            };
            if (this.props.clusterer) {
                appendScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBAgv3babs0SDij7y73kZI_KxqQ4wJ0wJE');
                appendScript('https://cdn.rawgit.com/googlemaps/v3-utility-library/07f15d84/infobox/src/infobox.js');
                appendScript('https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/src/markerclusterer.js',
                    this.createMap.bind(this)
                );
            } else {
                appendScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBAgv3babs0SDij7y73kZI_KxqQ4wJ0wJE',
                    this.createMap.bind(this)
                );
            }
        } else {
            this.createMap();
        }
    }

    /**
     * Set state with new props, clear old marker, then create new ones
     */
    componentWillReceiveProps(nextProps) {
        if (diff(this.props.locations, nextProps.locations)) {
            this.setState({locations: nextProps.locations}, () => {
                this.refreshMap();
                this.createMarkers();
            });
        }
    }

    /**
     * Clears the markers in the clusterer
     */
    refreshMap() {
        if (this.clusterer) {
            this.clusterer.clearMarkers();
        }
    }

    /**
     * Function that creates the map element
     */
    createMap() {
        if (!this.map) {
            this.map = new google.maps.Map(this.refs.map, {
                zoom: this.props.zoom || 0,
                center: new google.maps.LatLng(this.props.center.lat, this.props.center.lng),
                minZoom: this.props.minZoom || 0,
                maxZoom: this.props.maxZoom || 18,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                scrollwheel: false,
                zoomControl: false,
                scaleControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                styles: this.props.styles.map,
                scrollWheelZoom:'center'
            });
            this.centerMap();
            if (this.props.locations) {
                this.createMarkers();
            }
        }
    };

    /**
    * center the map
    */
    centerMap() {
        const calculateCenter = () => this.center = this.map.getCenter();
        google.maps.event.addDomListener(this.refs.map, 'idle', calculateCenter());
        google.maps.event.addDomListener(window, 'resize', this.map.setCenter(this.center));
    }

    /**
     * Create all markers from this.props.locations
     * and cluster them if specified in props
     */
    createMarkers() {
        if (util.isArray(this.props.locations)) {
            this.markers = this.props.locations.map(this.makeMarker);
        } else {
            this.makeMarker(this.props.locations);
        }
        if (this.props.clusterer) {
            this.clusterer = new window.MarkerClusterer(this.map, this.markers, {
                imagePath: '/images/black-marker',
                styles: this.props.styles.clusterer
            });
        }
    }

    /**
     * Function that creates a marker from location object
     * @prop {object} location
     */
    makeMarker(location) {
        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.coordinates.lat, location.coordinates.lng),
            icon: {
                map: this.map,
                url: location.icon || '/images/blue-marker.png',
                size: new google.maps.Size(44, 44),
                scaledSize: new google.maps.Size(22, 22)
            }
        });
        if (location.info) {
            const infoBox = new window.InfoBox({
                boxClass: `infoBox-${location.info.id}`,
                closeBoxURL: '',
                closeBoxMargin: '',
                disableAutoPan: true,
                pixelOffset: new google.maps.Size(-11, -33),
                enableEventPropagation: false,
                content: ReactDOMServer.renderToStaticMarkup(
                    <div className='md-show col-12-vh sm-col-6-vh md-col-4-vh'>
                        <InfoWindow {...location.info} />
                    </div>
                )
            });

            marker.addListener('click', () => {
                if (infoBox.getVisible()) {
                    this.clearActiveMarker();
                } else {
                    this.clearActiveMarker();
                    infoBox.open(this.map, marker);
                    this.setState({activeMarker: location}, () => setTimeout(() => {
                        this.active = {marker, infoBox};
                        const closeButton = infoBox.div_.querySelectorAll('.icon-cross')[0];
                        closeButton.addEventListener('click', () => this.active.infoBox.close(), false);
                        this.map.setCenter(this.active.marker.getPosition());
                        this.map.panBy(
                            infoBox.div_.offsetHeight / 2,
                            infoBox.div_.offsetWidth / 2
                        );
                    }, 0));
                }
            });
        }
        return marker;
    }

    /**
     * Removes the active marker
     */
    clearActiveMarker() {
        if (this.active) {
            this.setState({activeMarker: null});
            this.active.infoBox.close();
            this.active = null;
            this.map.panTo(this.map.getCenter());
        }
    }

    /**
     * Handle the custom zoom buttons
     */
    handleZoom(value) {
        this.map.setZoom(this.map.getZoom() + value);
    }

    render() {
        const minusButton = this.state.zoom <= this.props.minZoom ? 'opacity-3' : 'cursor-pointer';
        const plusButton = this.state.zoom >= this.props.maxZoom ? 'opacity-3' : 'cursor-pointer';

        return (
            <div className='relative'>
                <div className='box-shadow z1 absolute top-0 right-0 mt4 mr4 md-mr6 bg-white border border-grey-002'>
                    <div
                        onClick={this.handleZoom.bind(this, -1)}
                        className={`inline-block p3 line-height-1 border-right border-grey-002 user-select-none ${minusButton}`}
                        style={{
                            height: '42px',
                            width: '42px'
                        }}>
                        —
                    </div>
                    <div
                        onClick={this.handleZoom.bind(this, 1)}
                        className={`inline-block p3 line-height-1 user-select-none ${plusButton}`}
                        style={{
                            height: '42px',
                            width: '42px'
                        }}>
                        +
                    </div>
                </div>
                <div
                    ref='map'
                    className={this.props.className || ''}
                    style={this.props.style} />
                <div className='col-12 md-hide'>
                    {this.state.activeMarker && (
                        <InfoWindow
                            onHandleClose={this.clearActiveMarker.bind(this)}
                            {...this.state.activeMarker.info} />
                    )}
                </div>
            </div>
        );
    }

}

MapComponent.defaultProps = {
    center: {
        lat: 51.503548,
        lng: -0.095953
    },
    zoom: 3,
    minZoom: 2,
    maxZoom: 18,
    styles: {
        map: [
            {
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }, {
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'color': '#333333'
                    }, {
                        'lightness': 40
                    }
                ]
            }, {
                'elementType': 'labels.text.stroke',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#fefefe'
                    }
                ]
            }, {
                'featureType': 'administrative',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#fefefe'
                    }
                ]
            }, {
                'featureType': 'landscape',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#f5f5f5'
                    }
                ]
            }, {
                'featureType': 'landscape.man_made',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }, {
                'featureType': 'poi',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }, {
                'featureType': 'poi.park',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#caddb3'
                    }, {
                        'lightness': 45
                    }, {
                        'visibility': 'on'
                    }
                ]
            }, {
                'featureType': 'road.arterial',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry.fill',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            }, {
                'featureType': 'road.highway',
                'elementType': 'geometry.stroke',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            }, {
                'featureType': 'road.local',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            }, {
                'featureType': 'transit',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#f2f2f2'
                    }
                ]
            }, {
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'color': '#adcce5'
                    }
                ]
            }, {
                'featureType': 'water',
                'elementType': 'labels.text.fill',
                'stylers': [
                    {
                        'color': '#ffffff'
                    }
                ]
            }
        ],
        clusterer: [
            {
                url : '/images/black-marker1.png',
                height : 22,
                width : 22,
                anchor : [0, 0],
                textColor : '#ffffff',
                textSize : 10
            }, {
                url: '/images/black-marker2.png',
                height : 22,
                width : 22,
                anchor : [0, 0],
                textColor : '#ffffff',
                textSize : 10
            }, {
                url: '/images/black-marker3.png',
                height : 22,
                width : 22,
                anchor : [0, 0],
                textColor : '#ffffff',
                textSize : 10
            }
        ]
    }
};

module.exports = MapComponent;
