import React from 'react';
import Framed from './framed.jsx';
import Image from './image.jsx';
import util from '../../utilities';

class Carousel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentFrame: props.currentFrame
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({currentFrame: nextProps.currentFrame});
    }

    render() {
        if (!util.isArray(this.props.images)) {
            return (<span />);
        }

        const images = this.props.images.map((image, i) => {
            const hide = i !== this.state.currentFrame ? 'hide' : '';
            return (
                <figure
                    className='m0 bg-grey-001'
                    key={i}>
                    <div className={`${hide} relative sm-aspect-16-9`}>
                        <div className='sm-show sm-absolute top-0 bottom-0 left-0 right-0'>
                            <Framed
                                image={image} />
                        </div>
                        <div className='sm-hide'>
                            <Image
                                image={image} />
                        </div>
                    </div>
                </figure>
            );
        });

        return (
            <div>
                {images}
            </div>
        );
    }
}

module.exports = Carousel;
