/*
 * News listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing an News listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 * @prop {boolean} isHomepage
 */
class NewsItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        const category = util.try(() => this.props.item.news_category[0].name);
        const image = util.try(() => this.props.item.acf.listing_image);

        return (
            <a
                className={`block clearfix px4 pt4 pb3 md-px6 sm-pt0 ${util.try(() => this.props.isHomepage ? 'sm-pb6' : 'sm-pb7')}`}
                href={util.try(() => this.props.item.link) || `/exhibitions/${this.props.item.slug}`}>

                <div className='mb2 line-height-0'>–</div>
                {util.try(() => this.props.item.date) &&
                    <div className='mb3'>{moment(this.props.item.date).format('Do MMMM YYYY')}</div>
                }
                <h3
                    className='m0'>
                    <span
                        className='link'
                        dangerouslySetInnerHTML={{
                            __html: util.try(() => this.props.item.title.rendered) || util.try(() => this.props.item.post_title)
                        }} />
                </h3>
                <div
                    className='mb4 sm-mb6'
                    dangerouslySetInnerHTML={{__html: category || '&nbsp;'}} />
                {image &&
                    <div
                        className='relative'
                        style={{
                            height: 0,
                            paddingTop: `${(image.height / image.width) * 100}%`
                        }}>
                        <div className='absolute top-0 left-0 right-0'>
                            <Image
                                className='col-12'
                                smWidth={50}
                                image={image} />
                        </div>
                    </div>
                }
            </a>
        );

    }

}

module.exports = NewsItem;
