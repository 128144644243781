/*
 * Pagination
 */

import React from 'react';


/**
 * Class representing the pagination UI
 * @extends React.component
 * @prop {number} current
 * @prop {number} total
 * @prop {function} navigate
 */
class Pagination extends React.Component {

    render() {
        if (this.props.total <= 1) {
            return (<span />);
        }

        // Link component
        const Link = (props) => {
            if (props.page === props.current) {
                return (
                    <div className='bg-white line-height-1 py2 px3 inline-block border-right border-top border-white'>
                        <span className='block my1'>
                            {props.page}
                        </span>
                    </div>
                );
            } else {
                return (
                    <div
                        onClick={this.props.navigate.bind(null, props.page)}
                        className='line-height-1 py2 px3 inline-block border-right border-white cursor-pointer'>
                        <span className='block my1'>
                            {props.page}
                        </span>
                    </div>
                );
            }
        };

        // Build the pagination links
        const links = (current, total) => {
            let links = [];
            let lastPage = 0;
            for (let page = 1; page <= total; page++) {
                if (Math.abs(current - page) < 3) {
                    links.push(
                        <Link
                            key={page}
                            page={page}
                            current={current} />
                    );
                    lastPage = page;
                }
            }
            return links;
        };

        let next;
        let previous;

        if (this.props.current > 1) {
            previous = (
                <div
                    onClick={this.props.navigate.bind(null, this.props.current - 1)}
                    className='line-height-1 inline-block p2 cursor-pointer border-right border-white'>
                    <i className='icon-arrow-left my1 mx1' />
                </div>
            );
        } else {
            previous = (
                <div className='line-height-1 inline-block p2 border-right border-white muted'>
                    <i className='grey-005 icon-arrow-left my1 mx1' />
                </div>
            );
        }
        if (this.props.current < this.props.total) {
            next = (
                <div
                    onClick={this.props.navigate.bind(null, this.props.current + 1)}
                    className='line-height-1 inline-block p2 cursor-pointer'>
                    <i className='icon-arrow-right my1 mx1' />
                </div>
            );
        } else {
            next = (
                <div className='line-height-1 inline-block p2 muted'>
                    <i className='grey-005 icon-arrow-right my1 mx1' />
                </div>
            );
        }

        const pages = (
            <div className='inline-block'>
                <div className='clearfix center'>
                    {links(this.props.current, this.props.total)}
                </div>
            </div>
        );

        return (
            <div>
                <nav className='block clearfix bg-grey-001 py6 px4 md-px6'>
                    <div className='sm-py2 clearfix'>
                        <div className='right clearfix border border-white'>
                            {previous}
                            {pages}
                            {next}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }

}

module.exports = Pagination;
