/*
 * Artworks listing
 */

import React from 'react';
import AjaxListing from '../base/infinite-ajax-listing.jsx';
import ListingItem from './item.jsx';
import Message from '../shared/message.jsx';
import Loading from '../shared/loading.jsx';
import util from '../../../utilities';


/**
 * Class representing an Artworks listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 * @prop {number} featured
 * @prop {boolean} morePages
 */
class ArtworksListing extends AjaxListing {

    /**
     * Render
     */
    render() {

        const items = util.try(() => {
            return this.state.items.map((item, i) => {
                const clearfix = (i > 0 && (i + 1) % 3 === 0) ? (
                    <div className='sm-show clearfix' />
                ) : null;
                return (
                    <span key={item.id}>
                        <div className='col col-12 sm-col-4'>
                            <ListingItem
                                item={item}
                                index={i} />
                        </div>
                        {clearfix}
                    </span>
                );
            });
        });

        return (
            <div>
                <div>

                    {/* Listing */}
                    {this.state.status === 200 && this.state.items.length > 0 &&
                        <section className='relative border-bottom border-grey-002'>
                            <div className='sm-show mt7 absolute top-0 bottom-0 left-0 right-0'>
                                <div className='col col-4 height-100 border-right border-grey-002' />
                                <div className='col col-4 height-100 border-right border-grey-002' />
                            </div>
                            <div className='relative clearfix pt5 pb4 sm-pb0 sm-pt7'>
                                {items}
                            </div>
                            {this.props.morePages &&
                                <Loading active={this.state.loading} />
                            }
                        </section>
                    }

                    {/* Messages */}
                    {this.state.status === 200 && this.state.items.length === 0 &&
                        <div className='clearfix border-bottom border-grey-002'>
                            <Message message='Sorry, no artworks found. Please try a wider search.' />
                        </div>
                    }
                    {this.state.status !== 200 &&
                        <div className='clearfix border-bottom border-grey-002'>
                            <Message message='Sorry, something went wrong. Please try again later.' />
                        </div>
                    }

                </div>

            </div>
        );

    }

}

module.exports = ArtworksListing;
