/*
 * Video listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Video listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class VideosItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        const bgColor = (this.props.index % 2 === 0) ? 'bg-white' : 'bg-grey-000';

        return (
            <div className={`clearfix ${bgColor}`}>
                <a href={util.try(() => this.props.item.link)}>
                    <div className='col col-12 sm-col-4'>
                        <div className='m4 md-m6 aspect-16-9 overflow-hidden'>
                            <Image
                                className='absolute'
                                smWidth={35}
                                image={this.props.item.acf.listing_image} />
                        </div>
                    </div>
                    <div className='col col-12 sm-col-4'>
                        <div className='px4 sm-py4 md-p6'>
                                <div className='sm-line-height-1'>—</div>
                                <h3 className='m0'>
                                    <span
                                        className='link'
                                        dangerouslySetInnerHTML={{
                                            __html: util.try(() => this.props.item.title.rendered)
                                        }} />
                                </h3>
                                <div>
                                    {this.props.item.acf.duration}
                                </div>
                        </div>
                    </div>
                </a>
                <div className='col col-12 sm-col-4'>
                    <div className='px4 sm-py4 md-p6'>
                        <div className='sm-line-height-1'>—</div>
                        <div className='mb4 sm-mb6'>
                            {moment(this.props.item.date).format('DD MMMM YYYY')}
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

module.exports = VideosItem;
