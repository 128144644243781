/*
 *  Framed Images
 */

// jshint camelcase:false
// jshint unused:false

import React from 'react';
import utilities from '../../utilities';
import Image from './image.jsx';


/**
 * Framed image
 * With loader and support for responsive images
 * @prop object image - a WordPress image object
 * @prop string className - additional CSS classes
 * @prop string alt - alt text for the image
 * @prop number width - percentage base width
 * @prop number smWidth - percentage sm breakpoint width
 * @prop number mdWidth - percentage md breakpoint width
 * @prop number lgWidth - percentage lg breakpoint width
 * @prop string aspect - specify aspect classes for the container
 */
class FramedImage extends React.Component {
    render() {

        let ratio;
        let image;
        let breakpoint = 16/9;

        if (this.props.aspect) {
            const rx = /aspect-(\d+)-(\d+)/;
            const result = rx.exec(this.props.aspect);
            if (utilities.isArray(result)) {
                breakpoint = result[1] / result[2];
            }
        }
        breakpoint = breakpoint * 100;

        if (this.props.image &&
            this.props.image.width &&
            this.props.image.height) {
            ratio = Math.round((this.props.image.width / this.props.image.height) * 100);
        }

        if (ratio >= breakpoint) {
            // image is wider than frame - crop
            image = (
                // jshint ignore:start
                <Image
                    image={this.props.image}
                    className='height-100 width-auto centre-crop'
                    alt={this.props.alt}
                    width={(this.props.width) ? this.props.width * 1.4 : 140}
                    smWidth={(this.props.smWidth) ? this.props.smWidth * 1.4 : null}
                    mdWidth={(this.props.mdWidth) ? this.props.mdWidth * 1.4 : null}
                    lgWidth={(this.props.lgWidth) ? this.props.lgWidth * 1.4 : null} />
                // jshint ignore:end
            );
        } else {
            // image is narrower than frame - left align
            image = (
                // jshint ignore:start
                <div className='container height-100 relative'>
                    <div className='absolute top-0 bottom-0'>
                        <Image
                            image={this.props.image}
                            className='left height-100 width-auto'
                            alt={this.props.alt}
                            width={this.props.width}
                            smWidth={this.props.smWidth}
                            mdWidth={this.props.mdWidth}
                            lgWidth={this.props.lgWidth} />
                    </div>
                </div>
                // jshint ignore:end
            );
        }

        const classes = this.props.className || '';
        const aspect = this.props.aspect || 'sm-aspect-16-9';

        return (
            // jshint ignore:start
            <div className={'relative overflow-hidden ' + classes}>
                <div className='container'>
                    <div>
                        <div className={`aspect-1-1 ${aspect}`}></div>
                    </div>
                </div>
                <div className='absolute top-0 bottom-0 left-0 right-0'>
                    {image}
                </div>
            </div>
            // jshint ignore:end
        );
    }
};

module.exports = FramedImage;
