import React from 'react';
import moment from 'moment';
import Map from '../partials/ui/map.jsx';
import InfoWindow from '../partials/ui/info-window.jsx';
import Dropdown from '../partials/ui/dropdown-filter.jsx';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import util from '../utilities';


const filter = {
    map: {
        label: '',
        value: [],
        options: [
            {label: 'View all', value: null},
            {label: 'Collections', value: 'collections'},
            {label: 'Exhibitions', value: 'exhibitions'}
        ]
    }
};


class MapListing extends React.Component {

    constructor(props) {
        super(props);
        this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
        this.handleMarkerSelect = this.handleMarkerSelect.bind(this);
        this.state = {
            filters: filter,
            currentMarker: null
        };
    }

    handleDropdownSelect(key, value) {
        const current = filter.map.options.find(x => x.value === value[0]);
        this.setState({current: current});
    }

    handleMarkerSelect(marker) {
        this.setState({currentMarker: marker});
    }

    render() {
        const all = this.props.locations.collections.concat(this.props.locations.exhibitions);
        const items = util.try(() => this.props.locations[this.state.current.value]) || all;

        const formatDate = (date) => moment(date, 'YYYYMMDD').format('DD MMM. YYYY');
        const buildRange = (start, end) => {
            if (start && end) {
                return `${formatDate(start)} - ${formatDate(end)}`;
            } else if (start) {
                return formatDate(start);
            } else {
                return null;
            }
        };

        const locations = items.map((item, i) => {
            let link = item.link;
            if (item.type === 'collection' &&
                item.acf.detail_page === 'false') {
                link = false;
            }
            return {
                icon: item.type === 'collection'
                    ? '/images/red-marker.png'
                    : '/images/blue-marker.png',
                coordinates: item.acf.location,
                info: {
                    id: i,
                    title: item.title.rendered,
                    link: link,
                    address: item.acf.address,
                    image: item.acf.listing_image,
                    type: item.type,
                    dateRange: buildRange(item.acf.start_date, item.acf.end_date)
                }
            };
        });

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />

                {/* Title */}
                <section className='clearfix border-bottom border-grey-002'>
                    <div className='clearfix'>
                        <div className='col col-8'>
                            <div className='pb4 m0 px4 md-px6 pt6 sm-mt2 md-mt0 md-py7'>
                                <h2 className='m0 line-height-3'>
                                    In Display
                                </h2>
                            </div>
                        </div>
                        <div className='z2 relative col-right col-12 sm-col-4 md-col-3'>
                            <div className='px4 pb6 sm-py6 sm-my0 md-px6 md-py6 md-my2'>
                                <Dropdown
                                    current={util.try(() => this.state.filters.map.value)}
                                    onSelect={this.handleDropdownSelect}
                                    options={util.try(() => this.state.filters.map.options)} />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Map */}
                <Map
                    clusterer={true}
                    onHandleMarkerSelect={this.handleMarkerSelect}
                    locations={locations}
                    className='aspect-1-1 sm-aspect-3-2'
                    zoom={3} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );
    }

}

module.exports = MapListing;
