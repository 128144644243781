/*
 * Footer
 */

import React from 'react';


/**
 * Class representing the footer
 * @extends React.component
 * @prop {array} menu
 * @prop {string} current
 */
class Footer extends React.Component {

    render() {

        const menu = this.props.menu || [];
        const menuItems = menu.map((item, i) => {
            const border = (this.props.current && this.props.current === item.url) ?
            'border-grey-004' :
            'border-grey-002';
            const classes = (i > 0) ?
            `menu-link border-top ${border} py3 sm-pt2 sm-pb4` :
            `menu-link sm-border-top ${border} py3 sm-pt2 sm-pb4`;
            return (
                <span key={i}>
                    <div className='col col-right col-12 sm-col-3 px4 md-px6'>
                        <a
                            href={item.url}
                            className={classes}
                            dangerouslySetInnerHTML={{__html: item.title}} />
                    </div>
                    {(i > 0 && (i + 1) % 4 === 0) &&
                        <div className='clearfix' />
                    }
                </span>
            );
        });

        return (
            <footer className='py6 sm-pt8 sm-pb7'>
                <div className='mbn3 sm-mbn4'>
                    <div className='clearfix'>
                        {menuItems}
                    </div>
                </div>
            </footer>
        );

    }

};

module.exports = Footer;
