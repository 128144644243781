/*
 * Series listing container
 */

import React from 'react';
import ListingContainer from '../base/infinite-container.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing a Series listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class SeriesListingContainer extends ListingContainer {

    render() {

        return (
            <section className='relative border-bottom border-grey-002'>

                {/* Listing */}
                <Listing
                    path='/api/series'
                    items={this.props.items}
                    query={{
                        pages: this.state.pages
                    }}
                    morePages={this.state.morePages}
                    onLoad={this.handleLoad} />

            </section>
        );

    }
}

module.exports = SeriesListingContainer;
