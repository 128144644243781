/*
 * News
 */

import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Listing from '../../partials/listings/news/index.jsx';
import Filter from '../../partials/ui/dropdown-filter.jsx';
import util from '../../utilities';


/**
 * Class representing the news listing
 * @extends React.component
 * @prop {object} post
 * @prop {array} news
 */
class NewsListing extends React.Component {

    render() {

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />
                <Listing
                    filters={util.try(() => this.props.filters)}
                    page={util.try(() => this.props.news.page) || 1}
                    totalPages={util.try(() => this.props.news.totalPages)}
                    items={util.try(() => this.props.news.posts) || []} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );

    }
};

module.exports = NewsListing;
