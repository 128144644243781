/*
 * Newsletter
 */

import React from 'react';


/**
 * Class representing the Newsletter sign-up form
 * @extends React.component
 */
class Newsletter extends React.Component {
    render() {
        return (
            <form className='block clearfix' action='https://art.us15.list-manage.com/subscribe/post'>
                <input type='hidden' name='u' value='ba43c506b23a105b3a6b26329' />
                <input type='hidden' name='id' value='9cab5a8433' />
                <input type='hidden' name='orig-lang' value='1' />
                <div className='right'>
                    <button
                        type='submit'
                        className='btn bg-grey-004 hover-opacity-90 white px3 py2'>
                        <i className='icon-arrow-right' />
                    </button>
                </div>
                <div className='overflow-hidden'>
                    <input
                        type='email'
                        name='MERGE0'
                        autoCapitalize='off'
                        autoCorrect='off'
                        placeholder='Your email address'
                        className='btn col-12 bg-grey-001 px3 py2' />
                </div>
                <input type='hidden' name='ht' value='02cfe3d324de89d8b18a4f895904ca0919a6cc45:MTUwODE0Nzc0My4zMjY0' />
                <input type='hidden' name='mc_signupsource' value='hosted' />
            </form>
        );
    }
}


module.exports = Newsletter;
