/*
 * Editorial page
 */

import React from 'react';
import Page from '../partials/layout/page.jsx';
import Header from '../partials/global/header.jsx';
import Footer from '../partials/global/footer.jsx';
import Framed from '../partials/images/framed.jsx';
import util from '../utilities';


/**
 * Class representing the editorial pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 */
class Statements extends React.Component {
    render() {

        let statements;
        if (util.isArray(this.props.page.acf.statements)) {
            statements = this.props.page.acf.statements.map((statement, i) => {
                return (
                    <div
                        key={i}
                        className='col-12 sm-col-9 mb7'>
                        <div className='h2 text-bg-grey-002 mb0 rte' dangerouslySetInnerHTML={{__html:statement.statement_text}} />
                        {util.try(() => statement.statement_credit) &&
                            <div>
                                <div className='mt4 mb2 line-height-0'>–</div>
                                <div className='rte mt0' dangerouslySetInnerHTML={{__html:statement.statement_credit}} />
                            </div>
                        }
                    </div>
                );
            });
        }

        let images;
        if (util.isArray(this.props.page.acf.images)) {
            images = this.props.page.acf.images.map((image, i) => {
                return (
                    <div
                        key={i}
                        className='mt6 mb6 sm-mt7 sm-mb7'>
                        <div className='bg-grey-001'>
                            <Framed image={image} />
                        </div>
                        {util.try(() => image.caption) &&
                            <div className='px4 md-px6'>
                                <div className='mt4 mb2 line-height-0'>–</div>
                                <div dangerouslySetInnerHTML={{
                                    __html: image.caption
                                }} />
                            </div>
                        }
                    </div>
                );
            });
        }

        return (
            <Page>

                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={'/statements'} />

                <section className='border-bottom border-grey-002'>
                    <div className='p4 md-px6 sm-py7'>
                        <h1 className='mt3 mb3 sm-mt0 sm-mb0 line-height-3'>Statements</h1>
                    </div>
                </section>

                <section className='border-bottom border-grey-002'>
                    {statements &&
                        <div className='px4 pt6 md-px6 md-pt7'>
                            {statements}
                        </div>
                    }
                    {images &&
                        <div>
                            {images}
                        </div>
                    }
                </section>

                <Footer menu={util.try(() => this.props.settings.footer)} />

            </Page>
        );
    }
};

module.exports = Statements;
