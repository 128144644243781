/*
 * Instagram
 */

import React from 'react';
import util from '../../utilities';


/**
 * Class representing the homepage Instagram module
 * @extends React.component
 * @prop {string} handle
 * @prop {array} posts
 */
class Instagram extends React.Component {
    render() {

        let posts = [];
        let i = 0;

        if (util.isArray(this.props.posts)) {
            while (i < 4) {
                const image = util.try(() => this.props.posts[i].images.standard_resolution.url);
                const link = util.try(() => this.props.posts[i].link);
                if (image && link) {
                    posts.push(
                        <span key={i}>
                            <div className='relative col col-6 sm-col-3 pb4 px4 sm-pb6 md-px6'>
                                <a
                                    href={link}
                                    className='block'>
                                    <img
                                        className='col-12'
                                        src={image} />
                                </a>
                            </div>
                            {(i + 1) % 2 === 0 &&
                                <div className='clearfix sm-hide' />
                            }
                        </span>
                    );
                }
                i++;
            }
        }

        return (
            <section className='clearfix mt5 sm-mt7'>
                <div className='px4 md-px6 mb4 sm-mb6'>
                    <div className='mb2 line-height-0'>–</div>
                    <h3 className='m0 limit-line-length'>
                        <a
                            href={`https://instagram.com/${this.props.handle || ''}`}
                            className='link'>
                            Instagram <span dangerouslySetInnerHTML={{
                                __html: (this.props.handle) ? `@${this.props.handle}` : ''
                            }} />
                        </a>
                    </h3>
                </div>
                <div className='relative clearfix'>
                    <div className='sm-show absolute col col-3 border-right border-grey-002 height-100' />
                    <div className='sm-show absolute col col-6 border-right border-grey-002 height-100' />
                    <div className='sm-show absolute col col-9 border-right border-grey-002 height-100' />
                    {posts}
                </div>
            </section>
        );

    }
};

module.exports = Instagram;
