/*
 * Videos
 */

import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Listing from '../../partials/listings/videos/index.jsx';
import util from '../../utilities';


/**
 * Class representing the videos listing
 * @extends React.component
 * @prop {object} post
 * @prop {array} videos
 */
class VideosListing extends React.Component {

    render() {

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={`/${util.try(() => this.props.post.uri) || ''}`} />
                <section className='clearfix border-bottom border-grey-002'>
                    <div className='col col-12 p4 md-px6 sm-py7'>
                        <h2 className='mt3 mb3 sm-mt0 sm-mb0'>
                            Videos
                        </h2>
                    </div>
                </section>
                <Listing
                    page={util.try(() => this.props.videos.page) || 1}
                    totalPages={util.try(() => this.props.videos.totalPages)}
                    items={util.try(() => this.props.videos.posts) || []} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );

    }
};

module.exports = VideosListing;
