/*
 * Listing message
 */

import React from 'react';


/**
 * Message
 * @param {object} props
 */
const Message = (props) => {
    return (
        <div className='clearfix'>
            <div className='px4 py8 md-px6'>
                <span dangerouslySetInnerHTML={{__html: props.message || ''}} />
            </div>
        </div>
    );
};

module.exports = Message;
