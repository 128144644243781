/*
 * Exhibitions listing container
 */

import React from 'react';
import Filter from '../../ui/dropdown-filter.jsx';
import ListingContainer from '../base/container.jsx';
import Pagination from '../shared/pagination.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing an Exhibitions listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class ExhibitionsListingContainer extends ListingContainer {

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.filters = props.filters;
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            totalPages: data.totalPages
        });
    }

    render() {

        return (
            <section className='relative'>

                {/* Title */}
                <section className='clearfix border-bottom border-grey-002'>
                    <div className='clearfix'>
                        <div className='col col-8'>
                            <div className='pb4 m0 px4 md-px6 pt6 sm-mt2 md-mt0 md-py7'>
                                <h2 className='m0 line-height-3'>
                                    Exhibitions
                                </h2>
                            </div>
                        </div>
                        <div className='relative col-right col-12 sm-col-4 md-col-3'>
                            <div className='px4 pb6 sm-py6 sm-my0 md-px6 md-py6 md-my2'>
                                <Filter
                                    current={util.try(() => this.state.filters.type.value)}
                                    onSelect={this.handleFilter}
                                    options={util.try(() => this.state.filters.type.options)} />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Listing */}
                <Listing
                    path='/api/exhibitions'
                    items={this.props.items}
                    query={{
                        page: this.state.page,
                        type: util.try(() => this.state.filters.type.value)
                    }}
                    onLoad={this.handleLoad} />

                {/* Pagination */}
                <Pagination
                    current={this.state.page}
                    total={this.state.totalPages}
                    navigate={this.handlePage} />

            </section>
        );

    }
}

module.exports = ExhibitionsListingContainer;
