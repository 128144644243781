/*
 * Series
 */

import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Finder from '../../partials/artworks/finder.jsx';
import Listing from '../../partials/listings/series/index.jsx';
import util from '../../utilities';


/**
 * Class representing the Series listing
 * @extends React.component
 * @prop {object} post
 * @prop {array} artworks
 */
class Series extends React.Component {

    render() {

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />
                <section className='bg-blue-002'>
                    <div className='p4 md-px6 sm-py7'>
                        <h1 className='mt3 mb3 sm-mt0 sm-mb0'>
                            Artworks Finder
                        </h1>
                    </div>
                </section>
                <Finder
                    baseUrl={'artworks'}
                    filters={this.props.artworkFilters}
                    active={0} />
                <Listing
                    page={util.try(() => this.props.series.currentPage) || 1}
                    morePages={typeof util.try(() => this.props.series.nextPage) === 'number'}
                    items={util.try(() => this.props.series.posts) || []} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );

    }

};

module.exports = Series;
