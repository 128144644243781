/*
 * Exhibitions listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Exhibitions listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class ExhibitionsItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        const bgColor = (this.props.index) % 2 === 0 ? 'bg-white' : 'bg-grey-000';

        let dateRange;
        if (util.try(() => this.props.item.acf.start_date)) {
            dateRange = `${moment(this.props.item.acf.start_date, 'YYYYMMDD').format('DD MMMM YYYY')}`;
            if (util.try(() => this.props.item.acf.end_date)) {
                dateRange += util.try(() => ` - ${moment(this.props.item.acf.end_date, 'YYYYMMDD').format('DD MMMM YYYY')}`);
            }
        }

        const exhibitionType = util.try(() => this.props.item.exhibition_type[0].name);

        return (
            <div className={`clearfix ${bgColor}`}>
                <div
                    className='block clearfix'>
                    <div className='col col-12 sm-col-6'>
                        <div className='mx4 mt6 sm-mb6 md-m6 aspect-16-9 overflow-hidden'>
                            <Image
                                className='absolute v-centre'
                                smWidth={50}
                                image={util.try(() => this.props.item.acf.listing_image)} />
                        </div>
                    </div>
                    <div className='col col-12 sm-col-6'>
                        <div className='p4 sm-py6 md-p6'>
                            <div className='mt1 sm-mt0 sm-line-height-1'>—</div>
                            <h3 className='m0'>
                                <a
                                    href={util.try(() => this.props.item.link)}
                                    className='bold link-underline sm-hide'
                                    dangerouslySetInnerHTML={{
                                        __html: util.try(() => this.props.item.title.rendered)
                                    }} />
                                <span
                                    className='bold sm-show'
                                    dangerouslySetInnerHTML={{
                                        __html: util.try(() => this.props.item.title.rendered)
                                    }} />
                            </h3>
                            <div className='pt2 sm-p0'>
                                {util.try(() => this.props.item.acf.address)}
                            </div>
                            <div className='sm-pt4 sm-line-height-1'>—</div>
                            <div>{dateRange}</div>
                            <div>{exhibitionType}</div>
                            <div className='sm-show mb5 sm-m0 sm-pt5'>
                                <a className='link-underline' href={util.try(() => this.props.item.link)}>
                                    View details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

module.exports = ExhibitionsItem;
