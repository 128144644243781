/*
 * Series listing item
 */

import React from 'react';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Series listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class SeriesItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        const border = (this.props.index && this.props.index > 0) ? 'border-top border-grey-002' : '';

        let center = '';
        const aspect = 3 / 1;
        if (util.try(() => this.props.item.acf.listing_image)) {
            if ((this.props.item.acf.listing_image.width / this.props.item.acf.listing_image.height) > 3) {
                center = ' height-100 h-centre';
            } else {
                center = ' col-12 c-centre';
            }
        }

        return (
            <a
                className={`block p4 md-p6 ${border}`}
                href={util.try(() => this.props.item.link) || `/series/${this.props.item.slug}`}>
                <div className='clearfix mb4 mxn4 md-mxn6 mt3 md-mt4'>
                    <div className='col col-12 sm-col-6 px4 md-px6'>
                        <h3
                            className='h2 m0 limit-line-length'
                            dangerouslySetInnerHTML={{
                                __html: util.try(() => this.props.item.title.rendered) || util.try(() => this.props.item.post_title)
                            }} />
                        {util.try(() => this.props.item.acf.time) &&
                            <div
                                className='limit-line-length'
                                dangerouslySetInnerHTML={{__html: this.props.item.acf.time}} />
                        }
                    </div>
                    <div className='col col-12 sm-col-6 px4 md-px6'>
                        {util.try(() => this.props.item.acf.listing_text) &&
                            <div
                                className='sm-show limit-line-length'
                                dangerouslySetInnerHTML={{__html: this.props.item.acf.listing_text}} />
                        }
                        <div className='mt4'>
                            <span className='link-underline'>View Details</span>
                        </div>
                    </div>
                </div>
                {util.try(() => this.props.item.acf.listing_image) &&
                    <div className='aspect-3-1'>
                        <div className='absolute top-0 bottom-0 left-0 right-0 overflow-hidden'>
                            <Image
                                aspect='3-1'
                                className={'bg-grey-001' + center}
                                image={this.props.item.acf.listing_image} />
                        </div>
                    </div>
                }
            </a>
        );

    }

}

module.exports = SeriesItem;
