/*
 * News listing
 */

import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import Col from '../../layout/columns.jsx';
import ListingItem from './item.jsx';
import Message from '../shared/message.jsx';
import Loading from '../shared/loading.jsx';
import util from '../../../utilities';


/**
 * Class representing News listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 * @prop {number} featured
 * @prop {boolean} morePages
 */
class NewsListing extends AjaxListing {

    /**
     * Render
     */
    render() {
        const items = util.try(() =>
            this.state.items.map((item, i) => (
                <ListingItem
                    key={i}
                    item={item}
                    index={i} />
        )));

        return (
            <div>
                {/* Listing */}
                {(this.state.status === 200 && this.state.items.length > 0) &&
                    <section className='relative border-bottom border-grey-002'>
                        <div className='sm-show absolute top-0 bottom-0 left-0 col-6 border-right border-grey-002 mb6 zn1' />
                        <div className='clearfix relative sm-show'>
                            <Col items={items} count={2} />
                        </div>
                        <div className='clearfix sm-hide pt3 pb3 sm-py0'>
                            {items}
                        </div>
                        {this.props.morePages &&
                            <Loading active={this.state.loading} />
                        }
                    </section>
                }

                {/* Messages */}
                {this.state.status === 200 && this.state.items.length === 0 &&
                    <div className='clearfix border-bottom border-grey-002'>
                        <Message message='Sorry, no articles found.' />
                    </div>
                }
                {this.state.status !== 200 &&
                    <div className='clearfix border-bottom border-grey-002'>
                        <Message message='Sorry, something went wrong. Please try again later.' />
                    </div>
                }
            </div>
        );

    }

}

module.exports = NewsListing;
