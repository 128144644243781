/*
 * Page
 */

import React from 'react';


/**
 * Class representing the page layout
 * @extends React.component
 * @prop {jsx} children
 */
class Page extends React.Component {
    render() {
        return (
            <div className='sm-p6'>
                <article className='container bg-white'>
                    {this.props.children}
                </article>
            </div>
        );
    }
};

module.exports = Page;
