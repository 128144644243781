/*
 * Twitter
 */

import React from 'react';
import moment from 'moment';
import util from '../../utilities';


/**
 * Class representing the homepage Twitter module
 * @extends React.component
 * @prop {string} handle
 * @prop {object} tweet
 */
class Twitter extends React.Component {
    render() {

        let year = '';
        if (util.try(() => this.props.tweet.created_at)) {
            year = moment(this.props.tweet.created_at, 'ddd MMM DD HH:mm:ss ZZ YYYY').fromNow();
        }

        return (
            <a
                href={`https://twitter.com/${this.props.handle}`}
                className='block pb6 pt4 sm-p0 sm-pt6'>
                <div className='mb2 line-height-0'>–</div>
                <h3 className='m0 limit-line-length'>
                    <span className='link'>
                        Twitter @<span dangerouslySetInnerHTML={{
                            __html: this.props.handle || ''
                        }} />, <span dangerouslySetInnerHTML={{
                            __html: year
                        }} />
                    </span>
                </h3>
                <div
                    className='limit-line-length'
                    dangerouslySetInnerHTML={{__html: util.try(() => this.props.tweet.text) || ''}} />
            </a>
        );

    }
};

module.exports = Twitter;
