/*
 * Videos listing container
 */

import React from 'react';
import ListingContainer from '../base/container.jsx';
import Listing from './listing.jsx';
import Pagination from '../shared/pagination.jsx';
import util from '../../../utilities';


/**
 * Class representing an Videos listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class VideosListingContainer extends ListingContainer {

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            totalPages: data.totalPages
        });
    }

    render() {

        return (
            <section className='relative'>

                {/* Listing */}
                <Listing
                    path='/api/videos'
                    items={this.props.items}
                    query={{
                        page: this.state.page
                    }}
                    onLoad={this.handleLoad} />

                {/* Pagination */}
                <Pagination
                    current={this.state.page}
                    total={this.state.totalPages}
                    navigate={this.handlePage} />

            </section>
        );

    }
}

module.exports = VideosListingContainer;
