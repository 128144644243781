import React from 'react';
import util from '../../utilities';

/**
 * Class representing a Dropdown component for use with filters
 * @extends React.Component
 * @prop {string} type
 * @prop {function} onSelect
 * @prop {array} options
 */
class Filter extends React.Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.state = {
            toggle: false
        };
    }

    handleSelect(value) {
        this.setState({
            toggle: false
        }, this.props.onSelect.bind(null, this.props.type || 'type', value));
    }

    handleToggle() {
        this.setState({toggle: !this.state.toggle});
    }

    handleBlur(e) {
        const currentTarget = e.currentTarget;
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                this.handleToggle();
            }
        }, 0);
    }

    render() {
        if (!util.try(() => this.props.options)) {
            return null;
        }

        const Option = (props) =>  (
            <div
                className='bg-grey-006 pt3 pb2 px4 hover-bg-white col-12'
                onClick={this.handleSelect.bind(this, (props.value) ? [props.value] : [])}>
                {props.label}
            </div>
        );

        const optionsComponents = this.props.options
            .map((option, i) => (<Option key={i} {...option} />));

        const current = this.props.options
            .find((option) => option.value === util.try(() => this.props.current[0] || null));

        return (
            <div
                tabIndex={0}
                onBlur={(this.state.toggle) ? this.handleBlur : null}
                className={`relative outline-none bg-grey-002 cursor-pointer ${this.state.toggle ? 'box-shadow' : ''}`}>
                <div onClick={this.handleToggle}>
                    <div className='bg-grey-006 right pt3 pb2 px4'>
                        <i className={`icon-arrow-${this.state.toggle ? 'up' : 'down'}`} />
                    </div>
                    <div
                        className='overflow-hidden bg-grey-006 pt3 pb2 pl4 truncate'
                        onClick={this.handleToggle}
                        dangerouslySetInnerHTML={{__html: (current) ? current.label : ''}} />
                </div>
                <div className={`absolute left-0 right-0 z1 box-shadow ${this.state.toggle ? '' : 'hide'}`}>
                    {optionsComponents}
                </div>
            </div>
        );
    }

}

module.exports = Filter;
