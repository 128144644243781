/*
 * Artwork listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../../images/image.jsx';
import util from '../../../utilities';


/**
 * Class representing a Artwork listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 * @prop {boolean} paddingTop
 */
class ArtworksItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        let year;
        if (util.try(() => this.props.item.date)) {
            year = moment(this.props.item.date).format('YYYY');
        }

        const image = util.try(() => this.props.item.acf.images[0]);

        return (
            <a
                className={`block clearfix ${util.try(() => this.props.paddingTop ? 'px4 pt6 sm-py6 md-px6' : 'px4 pt2 pb5 md-px6 sm-pt0 sm-pb7')}`}
                href={util.try(() => this.props.item.link) || `/artworks/${this.props.item.slug}`}>
                <div className='mb2 line-height-0'>–</div>
                <h3
                    className='m0'>
                    <span
                        className='link'
                        dangerouslySetInnerHTML={{
                            __html: util.try(() => this.props.item.title.rendered) || util.try(() => this.props.item.post_title)
                        }} />
                </h3>
                <div
                    className='mb4 sm-mb6'
                    dangerouslySetInnerHTML={{__html: year}} />
                {image &&
                    <div
                        className='relative'
                        style={{
                            height: 0,
                            paddingTop: `${(image.height / image.width) * 100}%`
                        }}>
                        <div className='absolute top-0 left-0 right-0'>
                            <Image
                                className='col-12'
                                smWidth={this.props.smWidth || 35}
                                image={this.props.item.acf.images[0]} />
                        </div>
                    </div>
                }
            </a>
        );

    }

}

module.exports = ArtworksItem;
