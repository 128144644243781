/*
 * Artworks
 */

import React from 'react';
import Page from '../../partials/layout/page.jsx';
import Header from '../../partials/global/header.jsx';
import Footer from '../../partials/global/footer.jsx';
import Finder from '../../partials/artworks/finder.jsx';
import Listing from '../../partials/listings/artworks/index.jsx';
import util from '../../utilities';


/**
 * Class representing the artworks listing
 * @extends React.component
 * @prop {object} post
 * @prop {array} artworks
 */
class ArtworksListing extends React.Component {
    render() {

        const total = util.try(() => this.props.artworks.total) || 0;

        let hasFilters = false;
        util.try(() => {
            Object.keys(this.props.filters).map((key) => {
                if (util.isArray(this.props.filters[key].value)) {
                    hasFilters = true;
                }
            });
        });

        return (
            <Page>
                <Header
                    menu={util.try(() => this.props.settings.header)}
                    mobileMenu={util.try(() => this.props.settings.mobile)}
                    current={util.try(() => this.props.menuLocation)} />
                <section className='p4 md-px6 sm-py7'>
                    <h1 className='mt3 sm-mt0 mb0'>
                        Artworks Finder
                    </h1>
                    <div className='mb3 sm-mb0'>
                        <span dangerouslySetInnerHTML={{
                            __html: `${total} ${(total === 1) ? 'Result' : 'Results'}.`
                        }} />
                        {hasFilters &&
                            <a className='ml2 link-underline' href='/artworks'>Reset</a>
                        }
                    </div>
                </section>
                <Finder baseUrl={'artworks'} filters={this.props.filters} />
                <Listing
                    filters={this.props.filters}
                    pages={util.try(() => this.props.artworks.pages) || 1}
                    morePages={typeof util.try(() => this.props.artworks.nextPage) === 'number'}
                    items={util.try(() => this.props.artworks.posts) || []} />
                <Footer
                    menu={util.try(() => this.props.settings.footer)}
                    current={util.try(() => this.props.menuLocation)} />
            </Page>
        );

    }
};

module.exports = ArtworksListing;
