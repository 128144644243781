/*
 * Exhibition listing item
 */

import React from 'react';
import moment from 'moment';
import Image from '../images/image.jsx';
import util from '../../utilities';


/**
 * Class representing an Exhibition listing item
 * @extends React.component
 * @prop {object} item
 * @prop {number} index
 */
class ExhibitionItem extends React.Component {

    render() {

        if (!this.props.item) {
            return (<span />);
        }

        return (
            <a
                className='block clearfix p4 md-p6'
                href={util.try(() => this.props.item.link) || `/exhibitions/${this.props.item.slug}`}>
                <div className='mb2 line-height-0'>–</div>
                <h3
                    className='m0'>
                    <span
                        className='link'
                        dangerouslySetInnerHTML={{
                            __html: util.try(() => this.props.item.title.rendered) || util.try(() => this.props.item.post_title)
                        }} />
                </h3>
                <div
                    className='mb4 sm-mb6'
                    dangerouslySetInnerHTML={{__html: util.try(() => this.props.item.acf.address)}} />
                {util.try(() => this.props.item.acf.listing_image) &&
                    <Image
                        className='col-12'
                        smWidth={50}
                        image={this.props.item.acf.listing_image} />
                }
            </a>
        );

    }

}

module.exports = ExhibitionItem;
