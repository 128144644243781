/*
 * Listing loading indicator
 */

import React from 'react';


/**
 * Loading
 * @prop {boolean} active
 */
const Loading = (props) => {
    return (
        <div className='relative clearfix col-12 py5'>
            <img
                className={`block mx-auto ${(props.active) ? 'spin' : ''}`}
                style={{width: '.9rem', height: '.9rem'}}
                src='/images/loading.svg' />
        </div>
    );
};

module.exports = Loading;
