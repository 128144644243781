/*
 * Header
 */

import React from 'react';
import PageMenu from './page-menu.jsx';
import util from '../../utilities';


/**
 * Class representing the mobile menu
 * @extends React.component
 * @prop {array} menu
 */
class MobileTab extends React.Component {

    componentDidMount() {
        if (document) {
            document.body.scrollTop = 0;
            util.try(() => {
                document.getElementById('js-content').classList.add('overflow-hidden', 'sm-overflow-auto');
            });
        }
    }

    componentWillUnmount() {
        if (document) {
            util.try(() => {
                document.getElementById('js-content').classList.remove('overflow-hidden', 'sm-overflow-auto');
            });
        }
    }

    render() {
        return (
            <div className='border-top border-grey-001'>
                <div className='sm-hide bg-white fixed col-12 height-100 overflow-y-scroll z1'>
                    <div className='py4'>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

}



/**
 * Class representing the desktop search input
 * @extends React.component
 * @prop {string} term
 */
class DesktopSearch extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            active: (this.props.term) ? true : false
        };
    }

    toggle() {
        this.setState({
            active: !this.state.active
        }, () => {
            if (this.state.active) {
                this.refs.input.focus();
            }
        });
    }

    render() {

        return (
            <div className='col col-right col-4 px4 md-px6'>
                <div className='relative'>
                    <div
                        onClick={this.toggle}
                        className={`${this.state.active ? 'visibility-0' : ''} cursor-pointer menu-link border-top border-grey-002 pt2 pb4`}>
                        Search
                    </div>
                    <form
                        method='GET'
                        action='/search'
                        autoComplete='off'
                        className={`${this.state.active ? '' : 'hide'} absolute top-0 left-0 block col-12`}>
                        <div className='border-top border-grey-004'>
                            <div className='right'>
                                <button
                                    type='submit'
                                    className='button p0 pt2 pb4'>
                                    <div className='cursor-pointer search-icon mt1'>
                                        <div className='circle' />
                                        <div className='line' />
                                    </div>
                                </button>
                            </div>
                            <div className='overflow-hidden'>
                                <input
                                    name='term'
                                    type='text'
                                    ref='input'
                                    placeholder='Search'
                                    defaultValue={this.props.term}
                                    className='col-12 btn p0 pt2 pb4 pr3 border-0' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );

    }

}


/**
 * Class representing the header
 * @extends React.component
 * @prop {array} menu
 * @prop {array} mobileMenu
 * @prop {string} current
 * @prop {string} term - search term
 */
class Header extends React.Component {

    constructor(props) {
        super(props);
        this.handleMobileTab = this.handleMobileTab.bind(this);
        this.state = {
            mobileTab: false
        };
    }

    handleMobileTab(target) {
        this.setState({
            mobileTab: (this.state.mobileTab === target) ? null : target
        });
    }

    render() {

        const desktop = this.props.menu || [];
        const mobile = this.props.mobileMenu || [];

        const parent = util.try(() => {
            return this.props.menu.find((parent) => {
                if (parent.url === this.props.current) {
                    return true;
                } else {
                    return parent.children.find((child) => {
                        return child.url === this.props.current;
                    });
                }
            });
        });

        const current = (parent) ? parent.url : this.props.current;

        const desktopMenu = desktop.map((item, i) => {
            i++;
            const border = (current && current === item.url) ?
            'border-grey-004' :
            'border-grey-002';
            return (
                <span key={i}>
                    <div className='col col-right col-4 px4 md-px6'>
                        <a
                            href={item.url}
                            className={`menu-link border-top ${border} pt2 pb4`}
                            dangerouslySetInnerHTML={{__html: item.title}} />
                    </div>
                    {(i > 0 && (i + 1) % 3 === 0) &&
                        <div className='clearfix' />
                    }
                </span>
            );
        });

        // Desktop search
        desktopMenu.unshift(
            <DesktopSearch
                key='search'
                term={this.props.term} />
        );

        const mobileMenu = mobile.map((item, i) => {
            return (
                <div
                    key={i}
                    className='px4 md-px6'>
                    <a
                        href={item.url}
                        className='menu-link border-bottom border-grey-002 py3'
                        dangerouslySetInnerHTML={{__html: item.title}} />
                </div>
            );
        });

        return (
            <header className={this.state.mobileTab && 'fixed sm-relative col-12 height-100 sm-height-auto bg-white z1'}>

                <div className='relative clearfix'>
                    <div className='col col-12 sm-col-3'>
                        <div className='clearfix px4 py6 sm-py8 md-px6 line-height-0'>
                            {/* Mobile Menu Button */}
                            <div className='sm-hide right'>
                                <div
                                    onClick={this.handleMobileTab.bind(this, 'menu')}
                                    className={`cursor-pointer burger-icon ${(this.state.mobileTab === 'menu') ? 'js-is-active' : ''}`}>
                                    <span className='line' />
                                    <span className='line' />
                                    <span className='line' />
                                </div>
                            </div>
                            <div className='sm-hide right mr6'>
                                <div className='mr3'>
                                    <div
                                        onClick={this.handleMobileTab.bind(this, 'search')}
                                        className='cursor-pointer search-icon'>
                                        <div className='circle' />
                                        <div className='line' />
                                    </div>
                                </div>
                            </div>
                            {/* Logo */}
                            <div className='overflow-hidden'>
                                <a
                                    href='/'
                                    className='block sm-mb6'>
                                    <img
                                        className='logo'
                                        src='/images/logo.svg' />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Desktop Menu */}
                    <div className='sm-show col col-9'>
                        <div className='clearfix pt8'>
                            {desktopMenu}
                        </div>
                    </div>
                </div>

                {/* Mobile Menu */}
                {this.state.mobileTab === 'menu' &&
                    <MobileTab>
                        {mobileMenu}
                    </MobileTab>
                }

                {/* Mobile Search */}
                {this.state.mobileTab === 'search' &&
                    <MobileTab>
                        <form
                            method='GET'
                            action='/search'
                            autoComplete='off'
                            className='block p4'>
                            <div className='bg-grey-001'>
                                <div className='right'>
                                    <button
                                        type='submit'
                                        className='button bg-grey-001 px3 py2'>
                                        <div className='cursor-pointer search-icon inline mr3'>
                                            <div className='circle' />
                                            <div className='line' />
                                        </div>
                                    </button>
                                </div>
                                <div className='overflow-hidden'>
                                    <input
                                        name='term'
                                        type='text'
                                        className='col-12 button bg-grey-001 px3 py2' />
                                </div>
                            </div>
                        </form>
                    </MobileTab>
                }

                {/* Sub menu */}
                <PageMenu
                    menu={parent}
                    current={this.props.current} />

            </header>
        );

    }

};

module.exports = Header;
