/*
 * Collections listing container
 */

import React from 'react';
import Filter from '../../ui/dropdown-filter.jsx';
import ListingContainer from '../base/container.jsx';
import Pagination from '../shared/pagination.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing an Collections listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class CollectionsListingContainer extends ListingContainer {

    constructor(props) {
        super(props);
        this.handleLoad = this.handleLoad.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.state.totalPages = this.props.totalPages || 1;
        this.state.filters = props.filters;
    }

    /**
     * Handle load
     */
    handleLoad(data) {
        this.setState({
            totalPages: data.totalPages
        });
    }

    render() {

        return (
            <section className='relative'>

                {/* Title */}
                <section className='clearfix border-bottom border-grey-002'>
                    <h2 className='pl4 py4 md-pl6 md-py7 m0'>
                        Public Collections
                    </h2>
                </section>

                {/* Listing */}
                <Listing
                    path='/api/collections'
                    items={this.props.items}
                    query={{
                        page: this.state.page
                    }}
                    onLoad={this.handleLoad} />

                {/* Pagination */}
                <Pagination
                    current={this.state.page}
                    total={this.state.totalPages}
                    navigate={this.handlePage} />

            </section>
        );

    }
}

module.exports = CollectionsListingContainer;
